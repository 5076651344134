import axios    from    "axios"
import DT       from    '@/assets/modules/DateTime'

export default class Specials {

constructor(tmpl) {
    this.DT         =   new DT(tmpl)
    this.tmpl       =   tmpl
    this.script     =   'Specials.'
}

/*
sendProcessAboutError   =   (   isType,
                                title,
                                axios_,
                                oFormData, 
                                oResponseData)  =>  {
*/
sendProcessAboutError   =   (   isType,
                                title)  =>  {

    console.log('isType :: ' + isType)
    console.log('title :: ' + title)

}

axiosErrorCatch =   (callFromMethod, errorMsg, oFormData, axios_, index=0)  =>  {

    console.log(index)

    this.sendProcessAboutError( 0, 
                                errorMsg,
                                axios_,
                                oFormData, 
                                [])
}

formData = (axiosPath='')   =>  {

    let formData = new FormData()

    if(axiosPath.trim().length>0)
        formData.append('axios', axiosPath)

    //formData.append('from-app',             'is')
    formData.append('from-app',             'not')
    formData.append('net-heavenly-gate',    'dashboard')
    formData.append('net',                  localStorage.projectName)    

    formData.append('loginForAxios',        localStorage.loginForAxios)
    formData.append('passwordForAxios',     localStorage.passwordForAxios)
    //console.log(localStorage.loginForAxios)
    //console.log(localStorage.passwordForAxios)

    //formData.append('api',                  localStorage.URLRestApi)   

    return formData
}

writeToLogIfError =   (oDataObject, apiFormData, axiosPath='')   =>  {

    let isError         =   false
    Object.entries(oDataObject).forEach(([key, value]) => {    //
        if(key.trim().toLowerCase()==='error')  isError     = true
        if(key.trim().toLowerCase()==='axios')  axiosPath = value
    });

    if(!isError)    return  false

    let chargerId
    if(typeof this.tmpl.$store.state.oCurrentCharger['chg_id'] === 'undefined')    
        chargerId = 0
    else
        chargerId = this.tmpl.$store.state.oCurrentCharger['chg_id']

    let userId  
    if(typeof this.tmpl.$store.state.oCurrentUser['id'] === 'undefined')    
        userId = 0
    else 
        userId = this.tmpl.$store.state.oCurrentUser['id']
    

    let formItems   =   ''
    let comma       =   ''
    let item_       =   ''
    console.log(userId)
    for (const current of apiFormData.entries()) {
        if(current[0]!=='login' && current[0]!=='password')    {

            item_ = current[1]
            if(current[0]==='fields')   {
                let oInJSON = current[1];
                oInJSON =   oInJSON.replaceAll('"', '')
                oInJSON =   oInJSON.replaceAll('{', '')
                oInJSON =   oInJSON.replaceAll('}', '')
                oInJSON =   oInJSON.replaceAll('"', '')
                oInJSON =   oInJSON.replaceAll(' ', '')
                oInJSON =   oInJSON.replaceAll(':', '♥')    //3
                oInJSON =   oInJSON.replaceAll(',', '♠')    //6
                item_ = oInJSON
            }
            formItems += comma + current[0] + '~' + item_
            comma = '|'
        }
    } 
    let fields = '{'
    fields += '"userId":"'+userId+'",'
    fields += '"chargerId":"'+chargerId+'",'
    fields += '"errorText":"'+oDataObject.error+'",'
    fields += '"sectorInRest":"'+oDataObject.sector+'",'
    fields += '"lineInRest":"'+oDataObject.line+'",'
    fields += '"errorDateTime":"'+this.DT.stdFromJSDateTime(new Date())+'",'
    fields += '"workSpace":"'+window.location.href+'",'
    fields += '"formItems":"'+formItems+'"}'
    
    let formData = this.formData(axiosPath)

    formData.append('command', 'proc-addToErrLogFromDash')
    formData.append('fields',   fields)
    axios.post(localStorage.URLRestApi, formData).then(response => {
        console.error('ERROR FROM BACKGROUND')
        console.log(axiosPath)
        console.log(response.data)
        console.error('-----------')
    })    
    
    return true
}

isErrorResponseData =   (   axios_='', 
                            oFormData, 
                            response, 
                            requiredObject=false, 
                            mustBeGreaterThanZero=false,
                            simulatingError=0)  =>  { 
    
    let returnError = 0, errorText = '', oData = []

    if( (typeof response.data !== 'object' && requiredObject) || 
        parseInt(simulatingError)===1)   {

        errorText = 'Odpoveď z REST API nie je typu = object =, aj keď to systém vyžaduje'
        this.sendProcessAboutError( 1, errorText,
                                    axios_,
                                    oFormData, 
                                    response.data)

        if(parseInt(simulatingError)===0) returnError = 1
    }

    if( typeof response.data === 'object' && mustBeGreaterThanZero   ||
        parseInt(simulatingError)===2)   {

        oData = response.data
        if(parseInt(simulatingError)===2)   {
            let sError  =   '{'
            sError      +=  '"error":"K volaniu procedúry nad REST API aktuálne neexistuje oprávnenie ! ", '
            sError      +=  '"axios":"App.vue / dbProc.App.readChargersForDebugger()", '
            sError      +=  '"hostAddr":"178-143-45-184.static.orange.sk", '
            sError      +=  '"id":"37", ' 
            sError      +=  '"ip": "178.143.45.184", ' 
            sError      +=  '"line": "65", ' 
            sError      +=  '"sector":"SQLProcedures"}' 
    
            oData = JSON.parse(sError)            
        }

        if( this.OBJ.count(oData)===0 ||
            parseInt(simulatingError)===2)   {
            //console.error('EMPTY OBJECT :: ' + axios_)

            errorText = 'Rozhranie REST API vrátilo prázdny objekt pri axios požiadavke, pri ktorej je prázdny objekt neprípustný'
            this.sendProcessAboutError( 2, errorText,
                                        axios_,
                                        oFormData, 
                                        oData)


            if(parseInt(simulatingError)===0) returnError = 2
        }        
    }

    if( typeof response.data.error === 'string'  ||
        parseInt(simulatingError)===3) {

        errorText = 'REST API pri odštartovaní požadovaného procesu zistilo chybu pri spracovaní požiadavky, a zapísalo ju do chybovej databázy'
        this.sendProcessAboutError( 3, response.data.error,
                                    axios_,
                                    oFormData, 
                                    oData)

        if(parseInt(simulatingError)===0) returnError = 3
    }

    if(parseInt(returnError)>0) return true

    return false
}
    
}