import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/LoginToDashboard.vue')
    
  },

  {
    path: '/remote-point',
    name: 'remote-point',
    component: () => import('@/views/RemotePoint/_MainRemotePoint.vue')
  },

  {
    path: '/set-locales',
    name: 'set-locales',
   component: () => import('@/views/Locales/_MainLocales.vue')
  },  

  {
    path: '/set-connector',
    name: 'set-connector',
    component: () => import( '@/views/Connectors/_MainConnectors.vue')
  },

  {
    path: '/info-region',
    name: 'info-region',
    component: () => import('@/views/RegionInfo/_MainSetRegionInfo.vue')
  },

  {
    path: '/rewrite-region',
    name: 'rewrite-region',
    component: () => import('@/views/RegionInfo/RewriteRegionInfo.vue')
  },  

  {
    path: '/weather-connect',
    name: 'weather-connect',
    component: () => import('@/views/AladinWeather/_MainWeatherConnect.vue')
  },  

  {
    path: '/set-advertising',
    name: 'set-advertising',
    component: () => import('@/views/Advertising/_MainAdvertising.vue')
  },

  {
    path: '/help-content',
    name: 'help-content',
    component: () => import('@/views/HelpContent/_MainHelpContent.vue')
  },

  {
    path: '/access-right',
    name: 'access-right',
    component: () => import('@/views/AccessRight/_MainChangeAllRight.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
