export default class withOBJ {

constructor(tmpl) {this.tmpl = tmpl}

record  =   (oObject=[], keyValue='', value=0)  =>  {

    if(this.count(oObject)===0)     return []
    if(keyValue.trim().length===0)  return []
    if(typeof value !== 'number')   return []

    let oResult = []
    for(let oRecord of oObject) {
        if(parseInt(oRecord[keyValue])===parseInt(value))   {
            oResult = oRecord
        }
    }
    return oResult
}

count   =   (oObject)   =>  {

    if(!oObject.length)  
        if(Object.keys(oObject).length > 0) 
            return 1 
        else 
            return 0
            
    return oObject.length
}

sortObject = (  oObject, 
                sortKey,
                orderBy="asc") => {

    if(typeof oObject != "object") return []
    if(Object.keys(oObject).length==0) return []
    if(typeof sortKey != "string") return []
    if(sortKey.trim().length==0) return []
    if(typeof orderBy != "string") return []

    let oReturn = oObject.sort((key1, key2) => {
    if(orderBy==="desc") {
        if(key1[sortKey] < key2[sortKey]) return 1;
        if(key1[sortKey] > key2[sortKey]) return -1;            
    } else {
        if(key1[sortKey] > key2[sortKey]) return 1;
        if(key1[sortKey] < key2[sortKey]) return -1;            
    }

    return 0;
})

return oReturn
}
    
}