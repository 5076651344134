export default {
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltő modult keresek a márkámhoz - minimum 3 karakter"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazás megtalálja a gépe márkájával kompatibilis töltőmodult"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazás csak legalább három karakter beírása után kezdi meg a kompatibilis töltőmodul keresését"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A keresett elektromos gép jelenleg nem található az alkalmazáskatalógusban"])},
    "sub-menu": {
      "e-machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltő gépek"])},
      "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltőmodulok - kompatibilitás beállítása"])},
      "connectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő moduljainak beállítása"])}
    },
    "set-params": {
      "button-previev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltőmodulok előnézetének megjelenítése ideiglenes módosításokkal a paneleken a még meg nem írt változtatások végrehajtásához"])},
      "button-previev-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomásával (ha aktív kék háttérrel) a töltőcsatlakozó modulok lentebb megjelennek egy új beállítással, amely még nincs beírva az adatbázisba"])},
      "button-no-previev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje a paneleken végrehajtott módosításokat a módosítások végrehajtásához és a módosítások előnézetéhez, és térjen vissza az adatbázis jelenlegi állapotába"])},
      "button-no-previev-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomása (ha kék háttérrel aktív) törli a töltőmodulok megjelenített előnézetét, és megjeleníti az állapotot az adatbázisból"])},
      "set-brand-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebből a választóból lehetséges a csatlakozó szám"])},
      "set-brand-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rendelje hozzá a töltőmodulok katalógusában található márkát"])},
      "set-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítsa be a töltőmodult"])},
      "current-brand-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi modulmárka"])},
      "modul-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekintse meg ezt a modult"])},
      "modul-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel a kapcsolóval lehet beállítani, hogy a modulnak van-e száma"])},
      "modul-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kijelző a kijelzőn. Az ajánlott érték IGEN."])},
      "machine-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompatibilis gépek megjelenítése"])},
      "machine-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel a kapcsolóval beállítható, hogy legyen-e számuk a modul kinyitásakor"])},
      "machine-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kompatibilis elektromos gépek képeit vagy márkáit is megjelenítheti. Az ajánlott érték NEM."])},
      "images-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutasd az e-bike-okat"])},
      "images-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel a kapcsolóval beállítható, hogy legyen-e számuk a modul kinyitásakor"])},
      "images-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kompatibilis elektromos gépek képeit is megjeleníti. Az ajánlott érték NEM, hacsak nem tölt fel képeket elektromos gépekről is."])},
      "brand-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerékpár márkák"])},
      "brand-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel a kapcsolóval beállítható, hogy legyen-e számuk a modul kinyitásakor"])},
      "brand-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kompatibilis elektromos gépek márkáit is megjeleníti. A beállított érték NEM, a tulajdonos dönti el, kéri-e a kompatibilis márkák megjelenítését."])},
      "margin-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a modulnak a behúzása"])},
      "margin-left-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez mindig csak numerikus adat biztosítja a számmodul eltolását"])},
      "margin-left-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a kijelzőn képernyőpontokban, úgynevezett pixelekben, a kijelző bal szélétől. Javasoljuk, hogy először alaposan fontolja meg ezen adatok módosítását."])},
      "button-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be az adatokat ebbe az oszlopba"])},
      "button-save-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a gomb aktív (kék háttérrel), a megnyomása után megváltozik a töltő modul száma"])},
      "button-save-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be vannak írva az adatbázisba."])}
    },
    "e-machines": {
      "new-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá egy másik elektromos gépet a katalógushoz"])},
      "new-line-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomásával újabb elektromos töltőgépet vehet fel a katalógusba"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tölts fel több fotót az elektromos gépekről"])},
      "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomásával más elektromos járművekről készült új fényképeket lehet beilleszteni a rendszerbe"])},
      "find-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchsuchen Sie die Kette nach Marken für elektronische Maschinen"])},
      "find-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eine Zeichenfolge ein, um die Anzeige elektrischer Maschinen zu filtern"])},
      "find-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Eingabe einer Zeichenfolge in diese Eingabe wird der Anzeigefilter für elektrische Maschinen gestartet – es werden nur die Marken angezeigt, deren Name die eingegebene Zeichenfolge enthält"])},
      "remove-filter-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch doppeltes Bestätigen dieser Schaltfläche heben Sie den aktuell eingestellten Anzeigefilter für elektrische Maschinen auf"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gép márkája a csatlakozón keresztüli töltéshez"])},
      "brand-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márka rövidítése"])},
      "set-brand-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a gép márkáját az elektromos töltéshez"])},
      "set-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Például még egy távoli ponton lévő alkalmazásban lévő szűrő használatához is ideális a teljes márka megadása a gép típusmegjelölésével ebben a bemenetben."])},
      "set-short-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márka rövidítése"])},
      "set-short-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a parancsikon a töltő fizikai csatlakozója felett jelenik meg, ha a gép a csatlakozóhoz tartozik"])},
      "set-brand-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltőgép márkájának legalább 3 érvényes karaktert kell tartalmaznia"])},
      "set-short-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])},
      "button-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ír"])},
      "button-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyomja meg ezt a gombot a készülék márkáján végrehajtott módosítások mentéséhez"])},
      "offer-in-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajánlat az alkalmazásban"])},
      "view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a szemes ikon zöld, akkor a csatlakozók közelében lévő alkalmazásban ez az állvány a töltés menüjében lesz"])},
      "hidden-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha az ikon narancssárga, áthúzott szemmel, ez a töltőállvány nem lesz elérhető az alkalmazásban a csatlakozók közelében"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt a gépet nemrégiben törölték, ezért nem lehet beállítani, hogy a távoli ponton lévő alkalmazásban megjelenjen"])},
      "remove-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])},
      "remove-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen"])},
      "remove-really": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töröl"])},
      "remove-click-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon kétszeri megerősítésével az aktuális gépet töröltként jelöli meg"])},
      "not-remove-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem lehet törölni olyan elektromos gépet, amely bármely töltőcsatlakozóhoz van hozzárendelve"])},
      "is-remove-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Randizni"])},
      "is-remove-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ezt a gépet törölték - ha nem törli a törlést az egér újabb kétszeri megerősítésével, a rendszer a következő három napon belül fizikailag és véglegesen törli a gépet is"])},
      "is-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölve"])},
      "con-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehhez az elektromos géphez van hozzárendelve"])},
      "con-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktív"])},
      "con-no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inaktív"])},
      "con-remove-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["függőben lévő törlés"])},
      "con-no-remove-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nem várja a törlést"])},
      "con-not-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehhez az elektromos géphez nem tartozik töltőmodul"])}
    },
    "modules": {
      "new-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá egy másik csatlakozót a katalógushoz"])},
      "new-line-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomásával új csatlakozót adhat a katalógushoz - egy modult a töltőn"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltsön fel fényképeket további csatlakozókkal"])},
      "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomásával új fényképeket lehet hozzáadni a rendszerhez más csatlakozókról"])},
      "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karakterlánc keresése a csatlakozómodul címkéiben"])},
      "find-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szúrjon be egy karakterláncot a töltőcsatlakozó-modulok kijelzőjének szűréséhez"])},
      "find-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ebbe a bemenetbe ír be egy karakterláncot, akkor elindul a töltőcsatlakozó modulok megjelenítési szűrője - csak azok a modulok jelennek meg, amelyek nevében a megadott karakterlánc szerepel"])},
      "remove-filter-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gomb kétszeri megerősítésével törölheti a töltőcsatlakozó modulok aktuálisan beállított kijelzőszűrőjét"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltő csatlakozó márka"])},
      "brand-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Márka rövidítése"])},
      "set-brand-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a töltéshez használt csatlakozómodul márkáját"])},
      "set-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Például még egy távoli ponton lévő alkalmazásban lévő csatlakozócímke vagy szűrő használatához is ideális a teljes márka megadása a csatlakozómodul típusmegjelölésével ebben a bemenetben."])},
      "set-short-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Röviden"])},
      "set-short-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be a bal oldali szövegbevitelbe a márka rövidítését, ahogy az az alkalmazásban megjelenik a töltő fizikai csatlakozója felett"])},
      "set-brand-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A címkének legalább 3 érvényes karaktert kell tartalmaznia"])},
      "set-short-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 3 karakter"])},
      "button-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomása menti a csatlakozó modul címkéjén végzett módosításokat"])},
      "button-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ír"])},
      "offer-in-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajánlat az alkalmazásban"])},
      "view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a szem ikon zöld, az alkalmazás engedélyezi ezt a töltőcsatlakozó modult a monitor menüjében"])},
      "hidden-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha az ikon narancssárga, áthúzott szemmel, az alkalmazás nem jeleníti meg ennek a csatlakozónak a modulját a monitoron"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezt a csatlakozómodult nemrég törölték, ezért nem lehet beállítani, hogy a távoli pont alkalmazásában megjelenjen"])},
      "remove-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])},
      "remove-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen"])},
      "remove-really": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töröl"])},
      "remove-click-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon kétszeri megerősítésével az aktuális csatlakozómodult töröltként jelöli meg"])},
      "not-remove-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem lehet törölni olyan csatlakozót, amelyhez legalább egy elektromos gép csatlakozik"])},
      "is-remove-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Randizni"])},
      "is-remove-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ez a csatlakozómodul törölve lett - ha nem törli a törlést az egér újabb kétszeri megerősítésével, a rendszer a következő három napon belül fizikailag és véglegesen is törli ezt a csatlakozómodult"])},
      "is-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölve"])},
      "machines-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lehetőség van elektromos gépek csatlakoztatására, amelyek listája az alábbiakban található"])},
      "new-choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon egy másik töltőkészüléket, amely kompatibilis az ebben a sorban található csatlakozóval"])},
      "new-choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a menü tartalmazza az összes olyan elektromos gépet, amely nincs megjelölve az ebben a részben látható csatlakozóval kompatibilisként - ha egy másik gépet erősít meg ebben a választásban, ez a gép hozzáadódik a fenti töltőcsatlakozó modullal kompatibilisként"])},
      "with-connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A csatlakozó modulhoz"])},
      "click-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon kétszeri ellenőrzésével megszakítja az elektromos eszköz és a töltőcsatlakozó modul ebben a részben bemutatott kompatibilitását"])}
    }
  },
  "remote": {
    "condition-as-serialno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazások szám szerint rendezve"])},
    "condition-as-serialno-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomásával megjelenik egy táblázat a távoli pontokon lévő alkalmazások állapotáról, számuk szerint rendezve, vagy az aktuálisan elérhető telepítések listája. Ha nem rendelkezik felügyeleti jogokkal, csak azok a távoli pontok jelennek meg, amelyeknek Ön a tulajdonosa"])},
    "condition-as-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazások leírás szerint rendezve"])},
    "condition-as-note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomásával egy táblázatot kap a távoli pontokon lévő alkalmazás állapotáról, a helyek leírása szerint rendezve. Ha nem rendelkezik felügyeleti jogokkal, csak azok a távoli pontok jelennek meg, amelyeknek Ön a tulajdonosa"])},
    "current-serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenleg látható töltő"])},
    "sort-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kiegészítő töltők választéka szerint lehet válogatni"])},
    "sort-serialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["töltők száma"])},
    "sort-or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vagy"])},
    "sort-not-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltők tulajdonos nélkül"])},
    "sort-can-be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szerint lehet őket rendezni"])},
    "sort-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["töltők nevei"])},
    "read-new-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse be a távoli pont aktuális beállításait"])},
    "read-new-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha egy másik számítógépen módosítás történik az aktuális vagy másik távoli ponton, ennek a gombnak a megnyomásával minden módosítás betöltődik, és a távoli pont beállításainak megjelenítése frissül"])},
    "not-owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem rendelkezik alkalmazással a távoli ponton"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a töltő aktuális adatait"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A távoli ponttöltő rövid meghatározása"])},
    "error-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő rövid leírásának legalább 5 karakterből kell állnia"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A távoli pont alkalmazás utoljára csatlakozott a szerverhez"])},
    "err-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rossz dátum vagy idő a töltőn"])},
    "in-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["időben"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepítse újra vagy egyszerűen indítsa újra a vékony alkalmazást a távoli ponton"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosa a távoli pont alkalmazással"])},
    "owner-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név vagy cím"])},
    "owner-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosa természetes vagy jogi személyének neve"])},
    "owner-street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utca házszámmal"])},
    "owner-street-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosának címének részét képező utca és szám"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítószám"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosa címének irányítószáma"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Központ"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosának lakóhelye"])},
    "identify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azonosítás"])},
    "identify-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosának azonosítása"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosának kapcsolattartója az alkalmazással a távoli ponton"])},
    "person-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév, keresztnév"])},
    "person-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltővel kapcsolatban elérhető személy neve és vezetékneve"])},
    "person-mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "person-mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltőkkel kapcsolatos kapcsolattartó telefonszáma"])},
    "person-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levél"])},
    "person-mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltővel kapcsolatos kapcsolattartó elektronikus címe"])},
    "wifi-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztott WI-FI engedélyezése/letiltása a távoli ponton"])},
    "wifi-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentkezzen be a WIFI-re"])},
    "wifi-login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a töltő tulajdonosa engedélyezte a WIFI hálózat megosztását, itt a bejelentkezéshez meg kell adnia a bejelentkezést"])},
    "wifi-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI jelszó"])},
    "wifi-pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a töltő tulajdonosa engedélyezte a WIFI hálózat megosztását, adja meg itt a bejelentkezési jelszót"])},
    "wifi-is-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A WIFI engedélyezve van a távoli ponton"])},
    "wifi-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A WIFI nincs engedélyezve a távoli ponton"])},
    "wifi-login-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])},
    "wifi-pass-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További beállítások, órakijelzés, időintervallumok másodpercben, alvó üzemmód és adatfrissítés ellenőrzése"])},
    "digital-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitális óra"])},
    "global-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tétlen üzemmódban térjen vissza az alkalmazás elejére"])},
    "control-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az adatfrissítés ellenőrzése a szerveren"])},
    "active-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív alkalmazás"])},
    "global-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idő percekben"])},
    "global-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ide írja be az időt percben, amely azt az intervallumot fejezi ki, amely után az alkalmazás a beállított órával visszatér a kiinduláshoz, ha nincs tevékenység"])},
    "control-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idő percekben"])},
    "control-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ide írja be az időt percben, amely azt az időtartamot fejezi ki, amely után a töltőn lévő alkalmazásnak adatfrissítési ellenőrzést kell végrehajtania a szerveren, ezen az irányítópulton keresztül"])},
    "digital-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitális óra a bevezetőben"])},
    "analog-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analóg óra a bevezetőben"])},
    "global-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az elfogadható határ 1 és 999 perc között van"])},
    "control-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az elfogadható határ 1 és 999 perc között van"])},
    "actice-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő alkalmazás aktív"])},
    "actice-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltőalkalmazás nem aktív"])},
    "map-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a turistatérkép HTTP / HTTPS elérési útját"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg az útvonalat a túratérképhez, ideális esetben az API kulccsal és a töltő helyének koordinátáival"])},
    "map-path-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a turistatérkép HTTP / HTTPS elérési útját, ha a térkép nem nyilvános API-kulccsal. Ügyeljen a hirdetésekre és egyéb linkekre a térképen"])},
    "map-path-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A turistatérkép elérési útjának URL-címnek kell lennie (http://, https:/)"])},
    "promo-video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy promóciós videót, amelyet a töltőkliens kérésére lejátszanak"])},
    "promo-video-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a promóciós videó nincs megadva, az alkalmazás azt a videót fogja lejátszani, amelyet szabványosnak jelölt meg"])},
    "archa-create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre egy adatarchívumot az aktuális töltő mellékleteivel"])},
    "archa-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse le az adatarchívumot a mellékletekkel erről a töltőről"])},
    "at-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["készült ekkor"])},
    "at-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["időben"])},
    "in-windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["közvetlenül a Windows fájlböngészőben, előfordulhat, hogy a ...zip fájl tartalma nem jelenik meg megfelelően"])},
    "archa-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezen a hivatkozáson keresztül lehetséges az archív adatok letöltése csatolmányokkal az aktuális töltőhöz - használja a megfelelő ZIP eszközt, előfordulhat, hogy a letöltött csomaggal a Windows rendszer nem működik megfelelően"])}
  },
  "shots": {
    "current-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktuálisan beállított távoli pont"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse fel az alkalmazás alján megjelenítendő képeket a beállított ponton"])},
    "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazás képernyőjének alsó részében egy távoli ponton lehetőség van egyszerre nagy számú kép feltöltésére a helyi lemezről, amit ezen felül beállíthat úgy, hogy azok a határidőn belül valósághűen jelenjenek meg az alkalmazásban"])},
    "move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgassa vissza eggyel az aktuális képet a promóciós képek sorrendjében a beállított távoli ponton"])},
    "set-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a WEB oldal címét, ha ennek a képnek az egérrel történő megerősítése után meg kell jeleníteni az alkalmazásban, akkor az oldal felhasználási lehetősége nélkül jelenik meg"])},
    "set-url-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be a weblap címét - (például https://nazov-stranky.com vagy http://nazov-stranky.com, ha az oldal nem használ SSL-t)"])},
    "set-url-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebben a bejegyzésben lehetőség van a megnyitandó oldal nevének megadására és a távoli ponton lévő kliens ezt a képet az egérrel megerősíti. Az oldal anélkül jelenik meg, hogy tovább tudná használni az alkalmazásban. Ha nem adja meg az oldal címét, az egérrel történő megerősítést követően semmilyen folyamat nem indul el"])},
    "url-err-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A WEB oldal URL címének http:// előtaggal kell kezdődnie, az SSL protokollban https://"])},
    "url-err-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az első szintű domaint (com, tech, info...) ponttal kell elválasztani az URL-cím végén"])},
    "url-err-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az első szintű domain (com, tech, info...) maximum 5 karakterből állhat"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktuális kép további beállításai, megjelenítése az alkalmazásban, megjelenítési idő másodpercben vagy a kép fizikai törlése"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha megerősíti ezt az ikont az egérgomb kétszeri megnyomásával, az aktuális promóciós kép véglegesen fizikailag törlődik"])},
    "limit-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelenítési korlát"])},
    "limit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebben a bemenetben meg lehet adni egy értéket másodpercben, hogy hány másodpercig jelenjen meg ez a promóciós kép a csoporton belül - (a maximálisan beállítható idő legfeljebb 999 másodperc)"])},
    "button-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg az URL-t és a megtekintési korlátot"])},
    "set-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel az ikonnal az egérrel beállítható, hogy ez a promóciós kép megjelenjen-e az alkalmazásban (zöld ikon szemmel) vagy sem (narancssárga ikon áthúzott szemmel)"])},
    "move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgassa az aktuális képet eggyel előre a promóciós képek sorrendjében a beállított távoli ponton"])}
  },
  "owners": {
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon egy meglévő tulajdonost a megtekintéséhez vagy a módosításokhoz"])},
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bármelyik tulajdonost kiválaszthat vagy kereshet ebben a kijelölésben, ha erre jogosult"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre egy új töltőtulajdonost"])},
    "name-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A távoli ponton lévő töltő tulajdonosának neve, azonosítója"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tulajdonos neve vagy neve"])},
    "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb tulajdonos azonosító"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítószám"])},
    "name-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tulajdonos neve vagy neve"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebben a bejegyzésben lehetőség van a tulajdonos nevének vagy beosztásának megadására vagy szerkesztésére, illetve a töltő új tulajdonosának nevének vagy beosztásának megadására"])},
    "ident-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb azonosítás"])},
    "ident-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebben a bejegyzésben lehetőség van más tulajdonos azonosító megadására vagy szerkesztésére, ez lehet szervezeti azonosító szám, de bármilyen más szám vagy alfanumerikus karakterlánc is"])},
    "zip-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítószám"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az irányítószám ebben a bejegyzésben van megadva"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg legalább 5 érvényes karaktert"])},
    "ident-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg legalább 3 érvényes karaktert"])},
    "zip-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ajánlott formátum a 999 99"])},
    "address-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosának címe és székhelye a távoli ponton"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utca és bejárati szám a tulajdonos lakóhelyén belül"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulajdonos lakóhelye"])},
    "address-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utca és bejárati szám"])},
    "address-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebben a bejegyzésben lehetőség van a tulajdonos címének szerkesztésére vagy megadására, azaz a tulajdonos lakóhelye szerinti utcára és bejárati számra."])},
    "seat-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulajdonos lakóhelye"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebben a bejegyzésben lehetőség van a tulajdonos lakóhelyének, a városnak és annak részének vagy a falu nevének megadására vagy szerkesztésére"])},
    "address-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A címet helyesen kell megadni"])},
    "address-warn-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A címnek tartalmaznia kell az utca nevét és a bejárat számát"])},
    "address-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az utcanév nagybetűvel kezdődik"])},
    "address-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bejárat nem specifikus számozása"])},
    "address-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bejárati szám pontos jelölése hiányzik"])},
    "seat-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tulajdonos székhelyeként a várost vagy települést kell megadni"])},
    "seat-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A város vagy falu nevét nagybetűvel kell kezdeni"])},
    "seat-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A név illegális karaktereket tartalmaz"])},
    "seat-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A városnak vagy településnek legalább két karakterből kell állnia"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a tulajdonos aktuális adatait"])},
    "ship": {
      "choice-charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a töltőt a tulajdonjoghoz"])},
      "choice-charger-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebben a kiválasztásban olyan töltőt választhat vagy kereshet, amely még nem rendelkezik tulajdonjoggal – a töltő kiválasztásával a tulajdonjog hozzá lesz rendelve az aktuálisan beállított tulajdonoshoz."])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje a tulajdonjogot"])},
      "charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltőszám a szöveges definíciójával"])},
      "debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munkasáv"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egér dupla kattintásával törölheti a jobb oldalon felsorolt ​​töltő tulajdonjogát"])},
      "remove-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs jogosultsága a töltő tulajdonjogának megszüntetésére"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bal oldalon látható töltőre duplán kattintva bekapcsolja az alsó zöld sávot az alkalmazás hibakereséséhez"])},
      "debug-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bal oldalon látható töltőre duplán kattintva kikapcsolja az alsó zöld sávot az alkalmazás hibakereséséhez"])},
      "debug-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs jogosultsága a zöld tálcát bekapcsolni a bal oldalon felsorolt ​​töltőn hibakeresési célból"])}
    }
  },
  "account": {
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon egy hozzáférési fiókot (rendszergazdát) a központi irányítópulthoz a megtekintéséhez vagy a módosításokhoz"])},
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostantól módosíthatók a kiválasztott hozzáférési fiókok"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre egy új fiókot (rendszergazda) a központi irányítópulthoz való hozzáféréshez"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy rövid értesítést – a hozzáférés leírását"])},
    "note-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a megközelítésnek a rövid leírása"])},
    "note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be azt a szöveget, amely a szerkesztett megközelítést jellemzi"])},
    "note-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 10, maximum 80 karakter"])},
    "name-concat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részletesebb információ az adminisztrátorral való kapcsolatfelvételhez"])},
    "name-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az adminisztrátor / tulajdonos vezeték- és vezetékneve"])},
    "type-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendszergazda/tulajdonos hozzáférési típusa"])},
    "mobil-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lépjen kapcsolatba a mobiltelefonnal"])},
    "mail-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolattartási email cím"])},
    "name-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév, keresztnév"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kapcsolattartó személy, tulajdonos vagy ügyintéző vezeték- és vezetékneve"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 2 szó, mindegyik legalább 2 karakter"])},
    "type-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezeket az adatokat kell megadni, mert ez jellemzi a hozzáférési jogosultságokat"])},
    "type-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés típusát meg kell adni"])},
    "mobil-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonszám"])},
    "mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A távoli pont adminisztrátorának elérhetőségi telefonszáma (mobil)."])},
    "mobil-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formátum: +420 999 999999"])},
    "mail-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronikus cím"])},
    "mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tulajdonos vagy a rendszergazda elektronikus címe - úgynevezett e-mail"])},
    "mail-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cím helyes formája a "])},
    "name-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés az adatokhoz és a kapcsolatokhoz a jelenleg betöltött hozzáféréssel az irányítópulthoz"])},
    "login-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználónév"])},
    "pass-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
    "pass-conf-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó újra"])},
    "trial-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ig érvényes"])},
    "active-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív megközelítés"])},
    "delete-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés törlése"])},
    "login-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felhasználónév"])},
    "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebben a bejegyzésben az a név szerepel, amellyel a rendszergazda bejelentkezik a rendszerbe"])},
    "login-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])},
    "pass-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A név után írja be a jelszót"])},
    "pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha bejelentkezési nevet adott meg, akkor ebben a bejegyzésben meg kell adni a műszerfalba való bejelentkezéshez szükséges jelszót"])},
    "pass-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyolc karakter (a-z, A-Z, 0-9) + még egy karakter"])},
    "pass-conf-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A név után ismételje meg a jelszót"])},
    "pass-conf-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha bejelentkezési nevet adott meg, akkor a jelszót meg kell ismételni ebben a bejegyzésben, amelynek meg kell egyeznie a bal oldalon megadottal"])},
    "pass-conf-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a jelszavaknak egyezniük kell"])},
    "trial-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ebben a bejegyzésben dátumot adunk meg, a hozzáférés csak addig az időpontig érvényes vagy lesz érvényes"])},
    "trial-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés ideiglenes"])},
    "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az éppen megjelenített hozzáférés törölhető, a törlés fiktív, és bármikor visszavonható"])},
    "active-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktuálisan megjelenített hozzáférés beállítható aktívra vagy inaktívra"])},
    "active-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés aktív"])},
    "active-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés nem aktív"])},
    "delete-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés létezik"])},
    "delete-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés törlődik"])},
    "owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megjelenített hozzáférési fiók a töltő tulajdonosára vonatkozik"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a hozzáférési fiók aktuális adatait    "])}
  },
  "languages": {
    "remote-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenlegi távoli pont"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja hozzá egy másik ország nyelvét"])},
    "uses-all-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelenleg beállított töltő minden nyelvet használ, vagy valahol hiba van"])},
    "no-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emiatt jelenleg nincs elérhető nyelv"])},
    "in-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megfelelő távoli pont alkalmazás zászlói az alábbiakban látható sorrendben jelennek meg."])},
    "in-line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alább látható országzászlóval ellátott nyelv a távoli pont helyi nyelvének számít"])},
    "in-line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha módosítani szeretné az aktuális rendelést, először erősítse meg azt a zászlót, amelynek helyét módosítani kell"])},
    "in-line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["majd erősítse meg azt a zászlót, amelyre az egérkurzoron megjelenő zászlót el kell helyezni"])},
    "in-line-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bal és jobb oldalon látható nyilakkal is felcserélheti a zászlókat"])},
    "move-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgassa a zászlót"])},
    "move-flag-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bal felé"])},
    "move-flag-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jobb felé"])},
    "remove-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje a nyelv használatát a távoli pont alkalmazásban"])}
  },
  "login": {
    "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön bejelentkezési neve"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["név"])},
    "your-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bejelentkezési jelszavad"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
    "view-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon megerősítésével három másodpercig megjelenik a beírt jelszó"])},
    "login-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrzöm a megadott adatokat"])},
    "undef-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azonosíthatatlan hiba történt"])},
    "login-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helytelen bejelentkezési adatok"])},
    "enterTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépni"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezve"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentkezzen be"])},
    "out-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha megerősíti ezt a részt az egérrel, akkor kijelentkezik ebből az alkalmazásból"])},
    "type": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismeretlen felhasználói típus"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felügyelő"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltő tulajdonosa"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizsgáló"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt fejlesztő"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés határozott időre"])}
    }
  },
  "top-menu": {
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy távoli pont"])},
    "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hirdető"])},
    "langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvek"])},
    "connects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozók"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidék"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az időjárás"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segítség"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzáférés"])}
  },
  "xxxxxxxxxxxxxxxxxxxxxxxvideo": {
    "first-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon meg egy promóciós videót, amelyet a töltőkliens kérésére lejátszanak"])}
  },
  "sliders": {
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse fel a kiválasztott fájlokat"])},
    "count-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A feltöltött fájlok száma"])},
    "ext-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fájl kiterjesztése nem engedélyezett"])},
    "shot-move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgassa vissza a keretet eggyel"])},
    "shot-move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgassa a keretet eggyel előre"])},
    "shot-allow-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kép megjelenítése egy távoli ponton"])},
    "shot-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a képnek a megjelenítése le van tiltva a távoli ponton"])},
    "shot-view-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kép megjelenítési időkorlátja másodpercben"])},
    "shot-save-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a kép megjelenítési időkorlátját - max. 99 másodperc"])},
    "shot-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon kétszeri megerősítésével az aktuális kép fizikailag törlődik"])},
    "direct-param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a paraméter kötelező"])},
    "view-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltőn megjelenő felvételek száma"])},
    "move-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felcserélem a két felvételt egymással"])},
    "delete-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A törlés kétszeri megerősítése után törlöm az elemet"])},
    "max-limit-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A maximális megjelenítési korlát 99 másodperc"])},
    "limit-allow-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A képmegjelenítés korlátját másodpercben adhatja meg"])},
    "save-limit-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felírom a beállított határt a visszaigazolt lövésre"])},
    "set-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megerősített lövés aktívnak van beállítva"])},
    "set-no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megerősített felvétel inaktívként van beállítva"])},
    "load-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kiválasztott fájlokat feltöltöm a fenti töltőkészletbe"])},
    "out-of-sizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kép minimális mérete 1000 képpont / 320 képpont"])}
  },
  "switch": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IGEN"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEM"])}
  },
  "charger": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon másik alkalmazást a távoli ponton"])},
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy távoli pont"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kapcsolatba lépni"])}
  },
  "lang": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv hozzáadása"])}
  },
  "setFlags": {
    "line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megfelelő távoli pont alkalmazás zászlói az alábbiakban látható sorrendben jelennek meg. "])},
    "line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alább látható országzászlóval ellátott nyelv a távoli pont helyi nyelvének számít. "])},
    "line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha módosítani szeretné az aktuális rendelést, először erősítse meg azt a jelzőt, amelynek pozícióját módosítani kell, "])},
    "line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["majd erősítse meg azt a zászlót, amelyre az egérkurzoron megjelenő zászlót el kell helyezni. "])},
    "not-avail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktuálisan beállított távoli pont az összes nyelvet használja. "])},
    "not-avail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezért ebben az ablakban jelenleg nem érhető el más nyelv."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelvi alternatív törlése"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgassa a zászlót"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["irány balra"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jó irány "])}
  },
  "chargers": {
    "connect-01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A távoli pont alkalmazás utoljára csatlakozott a szerverhez"])},
    "connect-02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["időben"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a töltő aktuális adatait"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telepítse újra vagy egyszerűen indítsa újra a vékony alkalmazást egy távoli ponton"])},
    "note": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručná definícia nabíjačky na vzdialenom bode"])}
    },
    "owner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosa a távoli pont alkalmazással"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tulajdonos neve"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postai"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A város"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azonosítás"])}
    },
    "person": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosának kapcsolattartója az alkalmazással a távoli ponton"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév, keresztnév"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
    },
    "wifi": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztott WI-FI engedélyezése/letiltása a távoli ponton"])},
      "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI engedélyezése"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentkezzen be a WI-FI-be"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI jelszó"])},
      "title-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetőség a kísérő szöveg megadására, amely megjeleníthető, ha a WI-FI engedélyezve van"])},
      "title-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetőség a kísérő szöveg megadására, amely megjeleníthető, ha a WI-FI nincs engedélyezve"])}
    },
    "other-sets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További beállítások, órakijelzés, időintervallumok másodpercben, alvó üzemmód és adatfrissítés ellenőrzése"])},
    "clock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitális óra"])},
    "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tétlen üzemmódban térjen vissza az alkalmazás elejére"])},
    "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatfrissítések ellenőrzése a szerveren"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív alkalmazás"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg az útvonalat a túratérképhez, ideális esetben az API kulccsal és a töltő helyének koordinátáival"])},
    "error": {
      "note": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő alapleírásának legalább 5 karakterből kell állnia"])}
      },
      "restart": {
        "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktuális távoli ponton lévő alkalmazás újraindul, amikor legközelebb csatlakozik az adatbázishoz"])},
        "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megakadályozták az új telepítést a távoli pont oldalon"])}
      },
      "owner": {
        "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])},
        "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 szám"])},
        "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])},
        "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 3 karakter"])},
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+421 999 999999"])},
        "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meno.priezvisko--at--domena.sk"])}
      },
      "wifi": {
        "allow-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A WI-FI engedélyezve van a töltőn"])},
        "allow-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI nem engedélyezett a töltőnél"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább 5 karakter"])}
      },
      "digital-clock-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasszikus óra"])},
      "digital-clock-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitális óra"])},
      "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A minimális érték 3 (perc)"])},
      "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A minimális érték 3 (perc)"])},
      "active-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technikai szünet"])},
      "active-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktív alkalmazás"])},
      "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A turistatérkép elérési útjának URL-címnek kell lennie (http://, https:/)"])}
    }
  },
  "select": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A választó jelenleg ajánlat nélkül működik"])}
  },
  "access": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre egy új rendszergazdát"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be a szerkesztett rendszergazdát"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a megközelítésnek a rövid leírása"])},
    "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendszergazda/tulajdonos neve"])},
    "char-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az engedélyek jellemzői"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil elérhetőség száma"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolattartási email cím"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg bejelentkezési nevét"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg bejelentkezési jelszavát"])},
    "pass-icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszóbevitel megjelenítése"])},
    "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be újra a jelszót"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a hozzáférés aktív"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés töröltként van megjelölve"])},
    "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próba hozzáférés"])},
    "owner-necessary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, válasszon egy meglévőt, vagy adjon meg egy új töltőtulajdonost"])},
    "owner-chargers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felügyelőn, a fejlesztőn és a tesztelőn kívül tegyen elérhetővé bizonyos töltőket más típusú hozzáférések számára"])},
    "owner-chargers-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a töltőt, amelyhez a készlet tulajdonosának tulajdonjogot kíván rendelni"])},
    "error": {
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférési fiók alapvető leírásának legalább 5 karakterből kell állnia"])},
      "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utó- és vezetéknév legalább 5 karakter + szóköz"])},
      "char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meg kell adni a hozzáférési jellemzőket"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideális számformátum"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az e-mail cím helytelen formája"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg legalább 5 érvényes karaktert"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagybetűk kisbetűk, számok és egyéb karakterek"])},
      "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem egyezik a bal oldalon megadott jelszóval"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés jelenleg aktív"])},
      "not-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés átmenetileg le van tiltva"])},
      "not-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés nincs töröltként megjelölve"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés töröltként lett megjelölve"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kért formátum a"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megadott időpontig érvényes"])}
    }
  },
  "owner": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A hozzáférés a tulajdonoshoz van hozzárendelve"])},
    "exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meglévő tulajdonost választhat"])},
    "new-alter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vagy hozzon létre egy új tulajdonost"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre egy új tulajdonost"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosának neve"])},
    "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb tulajdonos azonosító"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítószám"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím, utcanév, leltár és sorozatszám"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Székhely, falu, város – ennek része"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írja be a változtatásokat a töltő tulajdonosainak"])},
    "error": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adjon meg legalább 5 érvényes karaktert"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább három érvényes karakter"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["99999 vagy 999 99 formátum"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Például Utcanév 423/1c"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legalább két karakter, A település neve - annak része"])}
    },
    "ship": {
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson duplán az egérrel a töltő tulajdonjogának megszüntetéséhez"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egérrel történő dupla megerősítéssel bekapcsolja a hibakeresőt - a zöld sávot az alsó részben"])},
      "debug-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egérrel történő dupla megerősítés kikapcsolja az aktivált hibakeresőt - az alsó részben lévő zöld sáv nem jelenik meg"])}
    }
  },
  "weather": {
    "use-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használt meteorológiai rendszer"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szélességi kör"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosszúság"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jegyzet"])},
    "sea-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tengerszint feletti magasság"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írjon információkat az időjárás-előrejelzésről"])},
    "azimut-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szélirány (azimut)"])},
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az időjárás előrejelzés jelenleg nem elérhető!"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egyértelmű"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["félig tiszta"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felhős"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szinte felhős"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teljesen felhős"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Óránkénti időjárás előrejelzés"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szélirány"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az eső"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szélsebesség"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Széllökések"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levegő nyomás"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havazik"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napkelte"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napnyugta"])}
  },
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vasárnap"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hétfő"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedd"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerda"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csütörtök"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Péntek"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szombat"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Január"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Február"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Március"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Április"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehet"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Június"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Július"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augusztus"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeptember"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Október"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "cms": {
    "deposit": {
      "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munka a CMS-tartalommal és annak letéttel"])},
      "head-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmentheti ennek a CMS-nek az aktuális tartalmát a betétbe, kiválaszthatja egy másik betétből, vagy törölheti a betétben létrehozott tartalmat"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzon létre letétet ebből a CMS-tartalomból"])},
      "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ezt a részt megerősíti, elhelyezheti a CMS aktuális tartalmát"])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betét betöltése ebbe a CMS-tartalomba"])},
      "insert-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rendelkezésre álló tárolókból tölthet be tartalmat ebbe a CMS-be"])},
      "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiválaszthat egy sablont a tárolóban elérhető sablonok közül, és betöltheti annak tartalmát ebbe a CMS-be"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje a tartalom letétjét"])},
      "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az aktuális CMS-hez kapcsolódóan létrehozott letétet bármikor törölheti, bár a tartalmat kényelmesen megőrzi a letétben"])},
      "not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a tartalom nincs letétben"])},
      "not-exist-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha az aktuális CMS-hez nem létezik letét, akkor azt célszerű megőrizni és naprakész állapotban tartani"])},
      "insert-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan letétbe szeretné helyezni ennek a CMS-nek a tartalmát ?"])},
      "insert-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyezze a CMS tartalmát a letétbe"])},
      "insert-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])},
      "read-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse be a kiválasztott betétet ebbe a CMS-be"])},
      "read-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])},
      "delete-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törli az ehhez a CMS-hez kapcsolódó betét tartalmát?"])},
      "delete-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje a letétet"])},
      "delete-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjelenítés a képernyőn"])},
      "preview-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gomb megnyomásával az aktuális CMS tartalma a beállított képernyő méreteiben jelenik meg"])},
      "remove-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje az összes CMS-tartalmat"])},
      "remove-all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jól gondolja meg, ha törölni szeretné a CMS összes aktuális tartalmát a gomb kétszeri megerősítésével"])}
    },
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sajnáljuk, a tartalom előkészítése még folyamatban van."])},
    "choice-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezen országok közül kiválasztható, hogy a teljes CMS-eszközön belül milyen nyelven kívánja kezelni a tartalmat."])},
    "line-move-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozgassa az aktuális sort eggyel feljebb a tartalomban."])},
    "line-move-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tartalomjegyzékben mozgassa eggyel lejjebb az aktuális sort."])},
    "line-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelenítse meg az aktuális sort az alkalmazásban."])},
    "line-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne jelenítse meg az aktuális sort az alkalmazásban."])},
    "line-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítsa az aktuális vonal beállításait és paramétereit."])},
    "line-add-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újabb sor hozzáadásához kattintson duplán a sor pozíciójára, ez a sor lent fog megjelenni."])},
    "copy-style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Másolja a sort az összes tartozékkal együtt. Kiegészítők alatt értjük a sor stílusbeállításait, a másolt sorhoz tartozó képeket, ha azok fel lettek töltve."])},
    "line-trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupla megerősítés, hogy az aktuális sort a kukába dobja a helyreállítás lehetőségével."])},
    "line-recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölt sor visszaállítása."])},
    "win-line-modal": {
      "close-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartsa meg a változtatásokat a sorban, és zárja be az ablakot."])},
      "close-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erősítse meg az egeret a sorbeállítások módosításait tartalmazó ablak bezárásához."])},
      "close-no-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson duplán az egérrel az ablak bezárásához a sorbeállítások módosításainak mentése nélkül. Az eredeti beállítás visszakerül a szerkesztett sorba."])}
    },
    "image": {
      "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Képek beállításai."])},
      "img-width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS sor bal oldalán van kép, akkor ezzel a paraméterrel megadhatja a szélességét. Úgy kell eljárni, hogy a kép ne maradjon torz."])},
      "img-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS sor bal oldalán van kép, akkor ezzel a paraméterrel megadhatja a magasságát. Úgy kell eljárni, hogy a kép ne maradjon torz."])},
      "img-margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS sor bal oldalán van kép, akkor ezzel a paraméterrel beállíthatja annak eltolását a sor felső határától."])},
      "img-margin-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS-sor bal oldalán van kép, akkor ezzel a paraméterrel beállíthatja annak eltolását a sor jobb szélétől."])},
      "img-margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS-sor bal oldalán van kép, akkor ezzel a paraméterrel beállíthatja annak eltolását a sor alsó határától."])},
      "img-margin-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS sor bal oldalán van kép, akkor ezzel a paraméterrel beállíthatja annak eltolását a sor bal szélétől."])},
      "img-width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS sor bal oldalán van kép, akkor ezzel a paraméterrel megadhatja a szélességét. Úgy kell eljárni, hogy a kép ne maradjon torz."])},
      "img-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS sor bal oldalán van kép, akkor ezzel a paraméterrel megadhatja a magasságát. Úgy kell eljárni, hogy a kép ne maradjon torz."])},
      "img-margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS-sor jobb oldalán van kép, akkor ezzel a paraméterrel beállíthatja annak eltolását a sor felső határától."])},
      "img-margin-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS-sor jobb oldalán van kép, akkor ezzel a paraméterrel beállíthatja annak eltolását a sor jobb szélétől."])},
      "img-margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS-sor jobb oldalán van kép, akkor ezzel a paraméterrel beállíthatja annak eltolását a sor alsó határától."])},
      "img-margin-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a CMS-sor jobb oldalán található kép, akkor ezzel a paraméterrel beállíthatja annak eltolását a sor bal szélétől."])},
      "image-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel az eszközzel új képet tölthet fel a bal oldali részre. A bal oldalon lévő kép lecserélődik. Ha a CMS-sor bal oldali része szöveget tartalmaz, az megmarad, és a kép törlésekor újra megjelenik."])},
      "image-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel az eszközzel új képet tölthet fel a jobb oldali részbe. A jobb oldali meglévő kép lecserélésre kerül. Ha a CMS-sor jobb oldali része szöveget tartalmaz, akkor az megmarad, és a kép törlésekor újra megjelenik."])},
      "upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illessze be a képet a sor bal oldali részébe"])},
      "upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illessze be a képet a sor jobb oldali részébe"])},
      "upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A CMS sor bal oldali részébe a szöveg mellett lehetőség van a helyi számítógépről kiválasztott kép beszúrására is. Lehetőség van megadni a kép szélességét, magasságát és a vonalhatártól való eltolást."])},
      "remove-upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje a képet a sor bal oldaláról"])},
      "remove-upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törölje a képet a sor jobb oldaláról"])},
      "remove-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a sor bal oldalán van kép, akkor az egér dupla kattintásával törölhető. Ha korábban bármilyen szöveget írt be a bal oldali részbe, az megmaradt, és ebben a részben újra megjelenik."])},
      "remove-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha a sor jobb oldalán van kép, akkor az egér dupla kattintásával törölheti. Ha korábban bármilyen szöveget írt be a jobb oldali részbe, az megmaradt, és ebben a részben újra megjelenik."])}
    },
    "edit-line": {
      "change-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módosítsa a szöveget"])},
      "change-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gomb megerõsítésével lehetõség nyílik a CMS sor bal oldali részének szövegében történõ beavatkozásokra, módosításokra."])},
      "save-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ír"])},
      "change-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gomb megerõsítésével lehetõség nyílik a CMS sor jobb oldali részének szövegében történõ beavatkozásokra, módosításokra."])},
      "copy-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha CMS-t (tartalomkezelést) használunk a munka megkönnyítése érdekében, a sor bal oldalán lehetőség van arra, hogy ugyanabban a sorban és annak ugyanabban a részében más nyelvről már elkészített szöveget másoljunk - vagyis egy másik nyelvi változatból. az aktuális CMS-tartalomból."])},
      "copy-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A CMS (tartalomkezelés) használatakor a munka megkönnyítése érdekében a sor jobb oldalán lehetőség van arra, hogy egy másik nyelvről ugyanabban a sorban és annak ugyanabban a részében már elkészített szöveget másoljunk át - vagyis egy másik nyelvi változatból. az aktuális CMS-tartalomból."])},
      "save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomásával minden változtatás elmentésre kerül, és a szövegszerkesztő mód megszűnik. Ha megnyomja a szemetes ikont a bal felső sarokban, az írott módosítások, beleértve a szöveges tartalmat is, visszaállnak."])},
      "no-save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szemetes ikon megnyomásával a változtatások mentése nélkül kilép a szövegszerkesztő módból."])},
      "no-save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szemetes ikon megnyomásával a változtatások mentése nélkül kilép a szövegszerkesztő módból."])},
      "save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a gombnak a megnyomásával minden változtatás elmentésre kerül, és a szövegszerkesztő mód megszűnik. Ha megnyomja a szemetes ikont a bal felső sarokban, az írott módosítások, beleértve a szöveges tartalmat is, visszaállnak."])},
      "change-l-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cserélje fel a sor bal és jobb részét."])},
      "width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítsa be a bal oldali vonal szélességét, minimum 1% / max. 99%."])},
      "width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítsa be a jobb oldali vonal szélességét, minimum 1% / max. 99%."])},
      "no-view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon megerősítésével állítsa be, hogy a sor bal oldali része ne jelenjen meg, a jobb oldali rész foglalja el a teljes sort."])},
      "view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha megerősíti az ikont a sor bal oldali részének megjelenítéséhez, a vonal mindkét részének szélessége 50%-ra változik."])},
      "no-view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erősítse meg az ikont a beállításhoz, hogy a sor jobb oldala ne jelenjen meg, a bal oldali rész foglalja el a teljes sort."])},
      "view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha megerősíti az ikon-beállítást úgy, hogy a vonal jobb oldali része megjelenjen, a vonal mindkét részének szélessége 50%-ra lesz inicializálva."])},
      "disabled-view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy adott sorban nem lehet minden részének megjelenítését kikapcsolni."])},
      "height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szükség esetén a sor bal oldali részének magasságát is beállíthatja. A magasság nem lehet kisebb 50 képpontnál, és nem haladhatja meg a sor teljes magasságát."])},
      "height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szükség esetén a sor jobb oldali részének magasságát is beállíthatja. A magasság nem lehet kisebb 50 képpontnál, és nem haladhatja meg a sor teljes magasságát."])},
      "font-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebből a választóból beállíthat egy másik betűtípust, amely a sor bal oldalán használandó. Ha teljesen új betűtípust szeretne hozzáadni, kérjük, forduljon a felső rendszergazdához."])},
      "font-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebből a választóból beállíthat egy másik betűtípust a sor jobb oldali részében. Ha teljesen új betűtípust szeretne hozzáadni, kérjük, forduljon a felső rendszergazdához."])},
      "color-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egérrel lehetőség van a szöveg színének megváltoztatására a sor bal oldalán. Az értéknek mindig hexadecimális formában (hexadecimális) kell lennie, három vagy hat karakterrel (1-től 9-ig, A-tól F-ig), ellenkező esetben érvénytelen."])},
      "color-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az egérrel lehetőség van a szöveg színének megváltoztatására a sor jobb oldalán. Az értéknek mindig hexadecimális formában (hexadecimális) kell lennie, három vagy hat karakterrel (1-től 9-ig, A-tól F-ig), ellenkező esetben érvénytelen."])},
      "font-size-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték lehetőséget ad a betűméret megváltoztatására a sor bal oldalán. A sok lehetőség közül ez a CMS az em egységet használja, mert ez relatív az érzékeny megjelenítésben való jó használathoz. Engedélyezett 0,5–4,9 (em)."])},
      "font-size-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték lehetőséget ad a betűméret megváltoztatására a sor jobb oldalán. A sok lehetőség közül ez a CMS az em egységet használja, mert ez relatív az érzékeny megjelenítésben való jó használathoz. Engedélyezett 0,5–4,9 (em)."])},
      "font-weight-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szerkesztett sor bal oldalán lehetőség van a használt betűtípus vastagságának beállítására. Az alkalmazások úgy vannak kifejlesztve, hogy a betűtípus vastagsága a lehető legérzékenyebben állítható be."])},
      "font-weight-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szerkesztett sor jobb oldalán lehetőség van a használt betűtípus vastagságának beállítására. Az alkalmazások úgy vannak kifejlesztve, hogy a betűtípus vastagsága a lehető legérzékenyebben állítható be."])},
      "font-style-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ami a betűstílust illeti, a sor bal oldalán normál betűtípust vagy dőlt betűt állíthatunk be. A többi opciót figyelmen kívül hagyták a betűtípusok közötti rossz kompatibilitás miatt."])},
      "font-style-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ami a betűstílust illeti, a sor jobb oldalán normál betűtípust vagy dőlt betűt állíthatunk be. A többi opciót figyelmen kívül hagyták a betűtípusok közötti rossz kompatibilitás miatt."])},
      "background-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a tartalomkezelés lehetővé teszi a háttérszín megváltoztatását akár csak a szerkesztett sor bal oldalán. Az értéknek mindig hexadecimális formában (hexadecimális) kell lennie, három vagy hat karakterrel (1-től 9-ig, A-tól F-ig), ellenkező esetben érvénytelen. A vonal csak egy részének színe nincs feltétlenül megadva."])},
      "background-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a tartalomkezelés lehetővé teszi a háttérszín megváltoztatását akár csak a szerkesztett sor jobb oldalán. Az értéknek mindig hexadecimális formában (hexadecimális) kell lennie, három vagy hat karakterrel (1-től 9-ig, A-tól F-ig), ellenkező esetben érvénytelen. A vonal csak egy részének színe nincs feltétlenül megadva."])},
      "line-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sor bal oldali részének szerkesztésekor lehetőség van a szövegsorok közötti szövegeltolás magasságának beállítására is. Ennek eredményeként az egyes szövegsorok függőlegesen eltolódnak. Ezt az értéket nem kell megadni."])},
      "line-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sor jobb oldali részének szerkesztésekor lehetőség van a szövegsorok közötti behúzás magasságának beállítására is. Ennek eredményeként az egyes szövegsorok függőlegesen eltolódnak. Ezt az értéket nem kell megadni."])},
      "text-align-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sor bal oldalán lehetőségünk van beállítani a szöveg vízszintes eltolását. balra - a szöveg balra, jobbra igazítását jelenti - a szöveg jobbra, középre igazítását jelenti - a szöveg középre igazítását és az igazítást - a szöveg egyenletes igazítását jelenti az elem teljes szélességében."])},
      "text-align-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sor jobb oldalán lehetőségünk van beállítani a szöveg vízszintes eltolását. balra - a szöveg balra, jobbra igazítását jelenti - a szöveg jobbra, középre igazítását jelenti - a szöveg középre igazítását és az igazítást - a szöveg egyenletes igazítását jelenti az elem teljes szélességében."])},
      "text-decor-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bónuszként a bal oldali részben lévő szöveget is aláhúzhatja az aláhúzás opcióval. Ha egyiket sem adja meg, a szöveg nem lesz aláhúzva."])},
      "text-decor-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bónuszként a jobb oldali rész szövegét is aláhúzhatja az aláhúzás kiválasztásával. Ha egyiket sem adja meg, a szöveg nem lesz aláhúzva."])},
      "padding-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon melletti értékek a szöveg behúzására szolgálnak a szerkesztett sor bal oldalán. További információk jelennek meg, ha az egérkurzort egy adott értékre állítja az ikon közvetlen közelében."])},
      "margin-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon melletti értékek a szerkesztett sor bal oldali részének felülről vagy alulról történő eltolására szolgálnak. A sor belső részét nem lehet balról vagy jobbról behúzni. További információk jelennek meg, ha az egérkurzort egy adott értékre állítja az ikon közvetlen közelében."])},
      "margin-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon melletti értékek a szerkesztett sor jobb oldali részének felülről vagy alulról történő eltolására szolgálnak. A sor belső részét nem lehet balról vagy jobbról behúzni. További információk jelennek meg, ha az egérkurzort egy adott értékre állítja az ikon közvetlen közelében."])},
      "padding-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az ikon melletti értékek a szerkesztett sor jobb oldalán lévő szöveg eltolására szolgálnak. További információk jelennek meg, ha az egérkurzort egy adott értékre állítja az ikon közvetlen közelében."])},
      "padding-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a bal oldalon lévő szöveg hány pixellel (ponttal) legyen eltolva a felső szegélyétől. Ha az érték üres, nincs felső eltolás beállítva."])},
      "margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a vonal bal oldali része hány pixellel (ponttal) legyen eltolva a vonalszegély tetejétől. Ha az érték üres, nincs felső eltolás beállítva."])},
      "margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a vonal jobb oldali hány pixellel (ponttal) legyen eltolva a vonalszegély tetejétől. Ha az érték üres, nincs felső eltolás beállítva."])},
      "margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a vonal bal oldali része hány pixellel (ponttal) legyen eltolva a vonalszegély aljától. Ha az érték üres, akkor nincs kitöltés beállítva."])},
      "margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a vonal jobb oldali hány pixellel (ponttal) legyen eltolva a vonalszegély aljától. Ha az érték üres, akkor nincs kitöltés beállítva."])},
      "padding-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a bal oldali rész szövege hány pixellel (ponttal) legyen eltolva a jobb szélétől. Ha az érték üres, nincs jobbra eltolás beállítva."])},
      "padding-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a bal oldali rész szövege hány pixellel (ponttal) legyen eltolva az alsó szegélyétől. Ha az érték üres, az alsó oldaltól nincs eltolás."])},
      "padding-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a bal oldalon lévő szöveg hány képponttal (ponttal) legyen eltolva a bal szélétől. Ha az érték üres, nincs bal oldali eltolás beállítva."])},
      "padding-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a jobb oldali részben lévő szöveg hány pixellel (ponttal) legyen eltolva a felső szegélyétől. Ha az érték üres, nincs felső eltolás beállítva."])},
      "padding-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a jobb oldali részben lévő szöveg hány pixellel (ponttal) legyen eltolva a jobb szélétől. Ha az érték üres, nincs jobbra eltolás beállítva."])},
      "padding-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a jobb oldali részben lévő szöveg hány pixellel (ponttal) legyen eltolva az alsó szegélyétől. Ha az érték üres, az alsó oldaltól nincs eltolás."])},
      "padding-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez az érték határozza meg, hogy a jobb oldali részben lévő szöveg hány pixellel (ponttal) legyen eltolva a bal szélétől. Ha az érték üres, nincs bal oldali eltolás beállítva."])},
      "height-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Állítsa be a teljes szerkesztett sor magasságát. Nem lehet kisebb 50 képpontnál (pont) és nem lehet nagyobb 1500 képpontnál (pont)."])},
      "background-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel az értékkel beállítható a teljes vonal háttérszíne. Az értéknek mindig hexadecimális formában (hexadecimális) kell lennie, három vagy hat karakterrel (1-től 9-ig, A-tól F-ig), ellenkező esetben érvénytelen."])},
      "margin-glob-icon-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jobb oldali ikon melletti értékkel beállítható a sor belső tárolóinak eltolása a sor felső határától. Ebben az összefüggésben javasoljuk a sor teljes magasságának beállítását is, amellyel a sor alsó részéhez képest is el lehet tolni."])},
      "margin-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel az értékkel beállítható a sor belső tárolóinak eltolása a sor felső határától. Ebben az összefüggésben javasoljuk a vonal teljes magasságának beállítását is, amellyel akár a vonal aljától is eltolás érhető el."])}
    },
    "view-delete": {
      "text-date-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sort időben törölték"])},
      "active-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csak az aktív sorok megjelenítése"])},
      "active-only-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gomb megerősítésével a töröltként megjelölt CMS-sorok is megjelennek."])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A törölt sorok megjelenítése is"])},
      "all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gomb megerősítésével a töröltként megjelölt CMS-sorok is megjelennek."])},
      "drop-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupla megerősítéssel a logikailag törölt CMS sor véglegesen törlődik, ez a törlés már nem vonható vissza."])},
      "restore-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kétszeres megerősítéssel a logikailag törölt CMS-sor visszakerül azon sorok közé, amelyek nem lettek töröltként megjelölve."])}
    }
  }
}