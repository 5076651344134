export default {
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadám nabíjací modul pre moju značku - aspoň 3 znaky"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia Vám môže nájsť nabíjací modul, kompatibilný so značkou Vášho stroja "])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia začne hľadať kompatibilný nabíjací modul až po zadaní aspoň troch znakov"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadaný elektrický stroj sa v katalógu aplikácie v súčasnosti nenachádza"])},
    "sub-menu": {
      "e-machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stroje pre nabíjanie"])},
      "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabíjacie moduly - nastavenie kompatibility"])},
      "connectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenie modulov pre nabíjačku"])}
    },
    "set-params": {
      "button-previev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť náhľad na moduly nabíjačiek s dočasne vykonanými zmenami v panely pre výkon zmien, ktoré zatiaľ nie sú zapísané"])},
      "button-previev-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla (ak je aktívne s modrým pozadím) sa nižšie zobrazia moduly konektorov nabíjačiek s novým nastavením, ktoré zatiaľ nie je zapísané v databáze"])},
      "button-no-previev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť vykonané zmeny v panely pre výkon zmien a náhľad na tieto zmeny a vrátiť sa ku aktuálnemu stavu v databáze"])},
      "button-no-previev-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla (ak je aktívne s modrým pozadím) sa zobrazený náhľad nabíjacích modulov zruší a zobrazí sa stav z databázy"])},
      "set-brand-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohto vyberača je možné ku konektoru číslo "])},
      "set-brand-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priradiť značku, ktorá sa nachádza v katalógu nabíjacích modulov"])},
      "set-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastaviť nabíjací modul"])},
      "current-brand-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálna značka modulu"])},
      "modul-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť tento modul"])},
      "modul-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týmto prepínačom možno nastaviť, či sa má modul číslo"])},
      "modul-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zobrazovať na displeji. Odporúčaná hodnota je ÁNO."])},
      "machine-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť kompatibilné stroje"])},
      "machine-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týmto prepínačom možno nastaviť, či sa majú pri otvorení modulu číslo"])},
      "machine-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zobrazovať aj obrázky alebo značky kompatibilných elektrických strojov. Odporúčaná hodnota je NIE."])},
      "images-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť e-bikes"])},
      "images-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týmto prepínačom možno nastaviť, či sa majú pri otvorení modulu číslo"])},
      "images-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zobrazovať aj obrázky kompatibilných elektrických strojov. Odporúčaná hodnota je NIE, ibaže by ste si ku elektrickým strojom nahrávali aj ich obrázky."])},
      "brand-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť značky e-bikes"])},
      "brand-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týmto prepínačom možno nastaviť, či sa majú pri otvorení modulu číslo"])},
      "brand-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zobrazovať aj značky kompatibilných elektrických strojov. Nastavená hodnota je NIE, je na rozhodnutí vlastníka či požaduje zobrazovanie kompatibilných značiek."])},
      "margin-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odsadenie tohto modulu"])},
      "margin-left-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento vždy len číselný údaj nám zabezpečí odsadenie modulu číslo"])},
      "margin-left-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na displeji v obrazovkových bodoch takzvaných pixeloch, a to z ľavého okraja displeja. Odporúčame zmenu tohto údaja najprv dôkladne zvážiť."])},
      "button-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať dáta v tomto stĺpci"])},
      "button-save-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak je tlačidlo aktívne (s modrým pozadím) po jeho stlačení zmeny na  module nabíjačky číslo"])},
      "button-save-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sa zapíšu do databázy"])}
    },
    "e-machines": {
      "new-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do katalógu pridať ďalší elektrický stroj"])},
      "new-line-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla dostanete možnosť pridať do katalógu ďalší elektrický stroj pre nabíjanie"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrať ďalšie fotografie elektro strojov"])},
      "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové fotografie ďalších elektro dopravných prostriedkoch možno do systému vložiť stlačením tohto tlačidla"])},
      "find-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadať reťazec v značkách elektronických strojov"])},
      "find-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložiť reťazec pre filtrovanie zobrazenia elektrických strojov"])},
      "find-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadaním reťazca do tohto vstupu bude odštartovaný filter zobrazenia elektrických strojov - zobrazia sa iba tie značky, ktorých názov obsahuje zadaný reťazec"])},
      "remove-filter-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením tohto tlačidla zrušíte aktuálne nastavený filter zobrazenia elektrických strojov"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značka stroja pre nabíjanie cez konektor"])},
      "brand-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skratka značky"])},
      "set-brand-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte značku stroja pre elektrické nabíjanie"])},
      "set-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napríklad aj pre použitie filtra v aplikácii na vzdialenom bode je ideálne do tohto vstupu zadať celú značku aj s typovým označením stroja"])},
      "set-short-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skratka značky"])},
      "set-short-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto skratka sa bude v prípade príslušnosti stroja ku konektoru zobrazovať nad fyzickým konektorom nabíjačky"])},
      "set-brand-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značka stroja pre nabíjanie musí obsahovať aspoň platné 3 znaky"])},
      "set-short-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspoň 5 znakov"])},
      "button-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať"])},
      "button-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla vykonané zmeny v značke tohto stroja zapíšete"])},
      "offer-in-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponúknuť v aplikácii"])},
      "remove-tile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento stroj bol nedávno zmazaný, preto nie je možné nastaviť jeho zobrazovanie v aplikácii na vzdialenom bode"])},
      "view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak je ikona s okom zelená, v aplikácii pri konektoroch tento stoj bude pre nabíjanie v ponuke"])},
      "hidden-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak je ikona oranžová s prečiarknutým okom, v aplikácii pri konektoroch nebude tento stoj pre nabíjanie v ponuke"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento stroj bol nedávno zmazaný, preto nie je možné nastaviť jeho zobrazovanie v aplikácii na vzdialenom bode"])},
      "remove-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
      "remove-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno"])},
      "remove-really": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať"])},
      "remove-click-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením tejto ikonky označíte aktuálny stroj za vymazaný"])},
      "not-remove-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie je možné vymazať elektrický stroj, ktorý je priradený ku niektorému konektoru nabíjačky"])},
      "is-remove-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K dátumu"])},
      "is-remove-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bol tento stroj zmazaný - ak ďalším  dvojitým potvrdením myšky zmazanie nezrušíte, systém v priebehu ďalších troch dní tento stroj vymaže aj fyzicky a definitívne"])},
      "is-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazané"])},
      "con-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ku tomuto elektrickému stroju je priradený"])},
      "con-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktívny"])},
      "con-no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neaktívny"])},
      "con-remove-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čaká na zmazanie"])},
      "con-no-remove-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nečaká na zmazanie"])},
      "con-not-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ku tomuto elektrickému stroju nie je priradený žiadny modul nabíjačky"])}
    },
    "modules": {
      "new-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať ďalší konektor do katalógu"])},
      "new-line-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla dostanete možnosť pridať do katalógu nový ďalší konektor - modul na nabíjačke"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrať fotografie s ďalšími konektormi"])},
      "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové fotografie ďalších konektorov možno do systému vložiť stlačením tohto tlačidla"])},
      "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadať reťazec v značkách modulov konektorov"])},
      "find-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložiť reťazec pre filtrovanie zobrazenia modulov konektorov pre nabíjanie"])},
      "find-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadaním reťazca do tohto vstupu bude odštartovaný filter zobrazenia modulov konektorov pre nabíjanie - zobrazia sa iba tie moduly, ktorých názov obsahuje zadaný reťazec"])},
      "remove-filter-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením tohto tlačidla zrušíte aktuálne nastavený filter zobrazenia modulov konektorov pre nabíjanie"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značka konektora nabíjačky"])},
      "brand-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skratka značky"])},
      "set-brand-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte značku modulu konektora pre nabíjanie"])},
      "set-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napríklad aj pre použitie označenia konektorov, alebo filtra v aplikácii na vzdialenom bode je ideálne do tohto vstupu zadať celú značku aj s typovým označením modulu konektora"])},
      "set-short-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skratka"])},
      "set-short-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte skratku značky uvedenej v textovom vstupe naľavo ako sa á zobrazovať v aplikácii nad fyzickým konektorom nabíjačky"])},
      "set-brand-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značka musí obsahovať aspoň platné 3 znaky"])},
      "set-short-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspoň 3 znaky"])},
      "button-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla vykonané zmeny v značke modulu tohto konektora zapíšete"])},
      "button-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať"])},
      "offer-in-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponúknuť v aplikácii"])},
      "view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak je ikona s okom zelená, v aplikácii bude možné tento modul konektora pre nabíjanie v ponuke na monitore"])},
      "hidden-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak je ikona oranžová s prečiarknutým okom, v aplikácii sa modul tohto konektora na monitore nebude zobrazovať"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento modul konektora bol nedávno zmazaný, preto nie je možné nastaviť jeho zobrazovanie v aplikácii na vzdialenom bode"])},
      "remove-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
      "remove-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno"])},
      "remove-really": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať"])},
      "remove-click-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením tejto ikonky označíte aktuálny modul konektora za vymazaný"])},
      "not-remove-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie je možné vymazať konektor, na ktorý je naviazaný aspoň jeden elektrický stroj"])},
      "is-remove-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ku dátumu"])},
      "is-remove-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bol tento modul konektora zmazaný - ak ďalším dvojitým potvrdením myšky zmazanie nezrušíte, systém v priebehu ďalších troch dní tento modul konektora vymaže aj fyzicky a definitívne"])},
      "is-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazané"])},
      "machines-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["môžete pripojiť elektrické stroje ktorých zoznam je nižšie"])},
      "new-choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte ďalší stroj pre nabíjanie kompatibilný s konektorom v tomto riadku"])},
      "new-choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V tejto ponuke sa nachádzajú všetky elektrické stroje, ktoré nie sú označené ako kompatibilné ku konektoru zobrazeného v tejto sekcií - potvrdením ďalšieho stroja v tomto výbere tento stroj bude pridaný ako kompatibilný s vyššie uvedeným modulom konektora pre nabíjanie"])},
      "with-connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ku modulu konektora"])},
      "click-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením tejto ikonky zrušíte kompatibilitu elektrického zariadenia a modulu konektora nabíjačky zobrazenej v tejto sekcií"])}
    }
  },
  "remote": {
    "condition-as-serialno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácie zoradené podľa čísiel"])},
    "condition-as-serialno-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla získate tabuľku so stavom aplikácii na vzdialených bodoch zoradené podľa ich čísiel, alebo zoznam inštalácii ktoré sú aktuálne k dispozícii. Ak nemáte práva supervízora, zobrazia sa iba vzdialené body ktorých ste vlastníkom"])},
    "condition-as-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácie zoradené podľa popisu"])},
    "condition-as-note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla získate tabuľku so stavom aplikácii na vzdialených bodoch zoradené podľa popisu miest kde sa nachádzajú. Ak nemáte práva supervízora, zobrazia sa iba vzdialené body ktorých ste vlastníkom"])},
    "current-serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne zobrazená nabíjačka"])},
    "sort-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výber ďalších nabíjačiek môže byť zotriedený podľa"])},
    "sort-serialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čísiel nabíjačiek"])},
    "sort-or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alebo"])},
    "sort-not-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabíjačky bez vlastníka"])},
    "sort-can-be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["môžu byť zotriedené podľa"])},
    "sort-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["názvov nabíjačiek"])},
    "read-new-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítať aktuálne nastavenia vzdialených bodov"])},
    "read-new-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak pri aktuálnom alebo aj inom vzdialenom bode príde ku zmenám vykonaným na inom počítači, stlačením tohto tlačidla všetky zmeny budú načítané, a zobrazenie nastavení vzdialeného bodu aktualizované"])},
    "not-owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadnu aplikáciu na vzdialenom bode nemáte vo vlastníctve"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať aktuálne informácie ku tejto nabíjačke"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručná definícia nabíjačky na vzdialenom bode"])},
    "error-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručná definícia nabíjačky musí obsahovať aspoň 5 znakov"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia vzdialeného bodu sa ku serveru naposledy pripojila"])},
    "err-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlý dátum alebo čas na nabíjačke"])},
    "in-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v čase"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinštalovať, alebo len reštartovať tenkú aplikáciu na vzdialenom bode"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník nabíjačky s aplikáciou na vzdialenom bode"])},
    "owner-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno alebo názov"])},
    "owner-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno fyzickej, alebo názov právnickej osoby vlastníka nabíjačky"])},
    "owner-street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica s číslom domu"])},
    "owner-street-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica a  číslo tvoriace súčasť adresy vlastníka nabíjačky"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštové smerové číslo v adrese vlastníka nabíjačky"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo vlastníka nabíjačky"])},
    "identify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikácia"])},
    "identify-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikácia vlastníka nabíjačky"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktná osoba vlastníka nabíjačky s aplikáciou na vzdialenom bode"])},
    "person-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko"])},
    "person-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko osoby, ktorú možno v súvislosti s nabíjačkou kontaktovať"])},
    "person-mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón"])},
    "person-mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefónne číslo na kontaktnú osobu ohľadne nabíjačky"])},
    "person-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "person-mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronická adresa na kontaktnú osobu ohľadne nabíjačky"])},
    "wifi-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povoliť / nepovoliť zdieľanú WI-FI na vzdialenom bode"])},
    "wifi-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásenie k WIFI"])},
    "wifi-login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak vlastník nabíjačky povolil zdieľať WIFI sieť, sem treba zadať login pre prihlásenie"])},
    "wifi-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo k WIFI"])},
    "wifi-pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak vlastník nabíjačky povolil zdieľať WIFI sieť, sem treba zadať heslo pre prihlásenie"])},
    "wifi-is-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI je na vzdialenom bode povolená"])},
    "wifi-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI na vzdialenom bode nie je  povolená"])},
    "wifi-login-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 znakov"])},
    "wifi-pass-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 znakov"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalšie nastavenia, zobrazenie hodín, časové intervaly v sekundách, spiaci režim a kontrola aktualizácie dát"])},
    "digital-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitálne hodiny"])},
    "global-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrat do úvodu aplikácie pri nečinnosti"])},
    "control-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola aktualizácie dát na serveri"])},
    "active-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívna aplikácia"])},
    "global-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas v minutách"])},
    "global-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte sem čas v minútach, ktorý bude vyjadrovať interval, po ktorom sa pri nečinnosti aplikácia vráti do úvodu s nastavenými hodinami"])},
    "control-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas v minutách"])},
    "control-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte sem čas v minútach, ktorý bude vyjadrovať interval, po ktorom má aplikácia na nabíjačke vykonať kontrolu aktualizácie dát na serveri, vykonaných cez tento Dashboard"])},
    "digital-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitálne hodiny v úvode"])},
    "analog-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analógové hodiny v úvode"])},
    "global-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptovateľný limit je od 1 do 999 minút "])},
    "control-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptovateľný limit je od 1 do 999 minút"])},
    "actice-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia nabíjačky je aktívna"])},
    "actice-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia nabíjačky nie je aktívna"])},
    "map-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte HTTP / HTTPS cestu k turistickej mape"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte cestu ku turistickej mape, ideálne s API kľúčom a súradnicami umiestnenia nabíjačky"])},
    "map-path-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte HTTP / HTTPS cestu k turistickej mape, ak nie je mapa verejná s API kľúčom. Pozor na reklamy a iné linky na mape"])},
    "map-path-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta ku turistickej mape musí byť URL adresa (http://, https:/)"])},
    "promo-video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte promo-video, ktoré sa spustí na podnet klienta nabíjačky"])},
    "promo-video-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak nie je promo-video zadané, aplikácia bude spúšťať video, ktoré má označené ako štandardné"])},
    "archa-create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť archív dáta s prílohami ku aktuálnej nabíjačke"])},
    "archa-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnuť archív dát s prílohami z tejto nabíjačky"])},
    "at-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vytvorené dňa"])},
    "at-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v čase"])},
    "in-windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priamo v súborovom prehliadači systému Windows sa obsah súboru ...zip nemusí správne zobrazovať"])},
    "archa-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomocou tohto linku je možné stiahnuť archívne dáta s prílohami ku aktuálnej nabíjačke - použite príslušný ZIP nastroj, systém Windows so stiahnutým balíkom nemusí pracovať správne"])}
  },
  "shots": {
    "current-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne nastavený vzdialený bod"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrať obrázky, ktoré sa majú zobrazovať v dolnej časti aplikácie v nastavenom bode"])},
    "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do dolnej časti obrazovky aplikácie na vzdialenom bode je možné z lokálneho disku nahrať naraz aj väčšie množstvo obrázkov, ktorým dodatočne nastavíte aby sa v aplikácii v rámci časových limitov aj reálne zobrazovali"])},
    "move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálny obrázok v poradí propagačných obrázkov pri nastavenom vzdialenom bode  vrátiť o jeden späť"])},
    "set-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte adresu WEB stránky, ak sa má v aplikácii zobraziť po potvrdení tohto obrázka myškou, stránka sa zobrazí bez možnosti ďalšieho použitia"])},
    "set-url-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte adresu WEB stránky - (napríklad https://nazov-stranky.com alebo http://nazov-stranky.com ak stránka nepoužíva SSL) "])},
    "set-url-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu je možné zadať názov stránky ktorá sa má otvoriť ak klient na vzdialenom bode potvrdí tento obrázok myškou. Stránka sa zobrazí bez toho aby ju bolo možné ďalej na aplikácii používať. Ak adresu stránky nezadáte, po potvrdení myškou sa nevyvolá žiadny proces"])},
    "url-err-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresa WEB stránky musí začínať http://, v SSL protokole https://"])},
    "url-err-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V URL adrese bodkou musí byť na konci oddelená doména prvej úrovne (com, tech, info...)"])},
    "url-err-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doména prvej úrovne (com, tech, info...) môže mať maximálne 5 znakov"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalšie nastavenia ku aktuálnemu obrázku, jeho zobrazenie v aplikácii, čas zobrazenia v sekundách, alebo fyzické zmazanie tohto obrázka"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak dvojitým stlačením tlačidla myšky túto ikonku potvrdíte, aktuálny propagačný obrázok bude definitívne fyzicky zmazaný"])},
    "limit-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit zobrazenia"])},
    "limit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu je možné zadať hodnotu v sekundách, koľko sekúnd má byť v rámci skupiny tento propagačný obrázok zobrazený - (maximálny možný čas k nastaveniu je až 999 sekúnd)"])},
    "button-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať URL a limit zobrazenia"])},
    "set-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri tejto ikonke je možné nastaviť myškou, či sa má tento propagačný obrázok v aplikácii zobrazovať (zelená ikonka s okom) alebo nie (oranžová ikonka s prečiarknutým okom)"])},
    "move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálny obrázok v poradí propagačných obrázkov pri nastavenom vzdialenom bode posunúť o jeden vpred"])}
  },
  "owners": {
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte existujúceho vlastníka pre nahliadnutie, alebo vykonanie zmien"])},
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V tomto výbere môžete vybrať alebo vyhľadať ktoréhokoľvek vlastníka, ako máte na to oprávnenie"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť nového vlastníka nabíjačky"])},
    "name-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov vlastnika nabíjačky na vzdialenom bode, jeho identifikácia"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov alebo meno vlastníka"])},
    "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iná identifikácia vlastníka"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
    "name-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov alebo meno vlastníka"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu je možné zadať alebo editovať meno či názov vlastníka, alebo zadať meno alebo názov nového vlastníka nabíjačky"])},
    "ident-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iná identifikácia"])},
    "ident-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu je možne zadať alebo editovať inú identifikáciu vlastníka, môže to byť identifikačné číslo organizácie, ale aj akékoľvek iné číslo alebo alfanumerický reťazec"])},
    "zip-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu sa zadáva poštové smerovacie číslo"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte aspoň 5 platných znakov"])},
    "ident-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte aspoň 3 platné znaky"])},
    "zip-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doporučený formát 999 99"])},
    "address-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa a sídlo vlastnika nabíjačky na vzdialenom bode"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica a číslo vchodu v rámci sídla vlastníka"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo vlastníka"])},
    "address-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica a číslo vchodu"])},
    "address-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu je možne editovať  alebo zadať adresu vlastníka, čiže ulicu a číslo vchodu kde vlastník sídli"])},
    "seat-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo vlastníka"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu je možne zadať alebo editovať sídlo vlastníka, mesto a jeho časť, alebo názov obce"])},
    "address-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa musí byť riadne zadaná"])},
    "address-warn-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa musí obsahovať názov ulice a číslo vchodu"])},
    "address-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov ulice začína veľkým písmenom"])},
    "address-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nekonkrétne číslovanie vchodu"])},
    "address-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chýba presné označenie čísla vchodu"])},
    "seat-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesto alebo obec ako sídlo vlastníka treba zadať"])},
    "seat-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov mesta alebo obce musí začínať veľkým písmenom"])},
    "seat-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov obsahuje nepovolené znaky"])},
    "seat-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesto alebo obec musí mať aspoň dva znaky"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať aktuálne údaje k tomuto vlastníkovi"])},
    "ship": {
      "choice-charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nabíjačku, ktorej má byť pridelené vlastníctvo"])},
      "choice-charger-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V tomto výbere môžete vybrať alebo vyhľadať nabíjačku ku ktorej zatiaľ neexistuje vlastníctvo - vybratím nabíjačky bude ku nej priradené vlastníctvo pre aktuálne nastaveného vlastníka"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť vlastníctvo"])},
      "charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo nabíjačky s jej textovou definíciou"])},
      "debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovná lišta"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením myšky vlastníctvo ku nabíjačke uvedenej vpravo zrušíte"])},
      "remove-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre zrušenie vlastníckeho práva ku nabíjačke nemáte oprávnenie"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením myšky ku nabíjačke uvedenej vľavo zapnete dolnú zelenú lištu pre ladenie aplikácie"])},
      "debug-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením myšky ku nabíjačke uvedenej vľavo vypnete dolnú zelenú lištu pre ladenie aplikácie"])},
      "debug-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K zapnutiu zelenej pracovnej lišty k nabíjačke uvedenej vľavo pre účely ladenia apikácie nemate oprávnenie"])}
    }
  },
  "account": {
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte prístupový účet (správcu) k centrálnemu dashboard pre zobrazenie, alebo vykonanie zmien"])},
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vo vybranom prístupovom účte je teraz možné vykonávať zmeny"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť nový účet (správcu)  pre prístup do centrálneho dashboard"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte stručnú notifikáciu - popis pre tento prístup"])},
    "note-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručný popis k tomuto prístupu"])},
    "note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte text, ktorý bude editovaný prístup charakterizovať"])},
    "note-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 10, maximálne 80 znakov"])},
    "name-concat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnejšie údaje pre prípadné kontaktovanie správcu"])},
    "name-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko správcu / vlastníka"])},
    "type-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh prístupu správcu / vlastníka"])},
    "mobil-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktný mobil"])},
    "mail-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktná elektronická adresa"])},
    "name-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko kontaktnej osoby, vlastníka alebo správcu"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 2 slová, každé aspoň 2 znaky"])},
    "type-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento údaj treba zadať, pretože charakterizuje oprávnenia prístupu"])},
    "type-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh prístupu treba zadať"])},
    "mobil-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefónne číslo"])},
    "mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktné telefónne číslo (mobil) na správcu vzdialeného bodu"])},
    "mobil-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát +420 999 999999"])},
    "mail-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronická adresa"])},
    "mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronická adresa vlastníka alebo správcu - takzvaný e-mail"])},
    "mail-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správny tvar adresy je "])},
    "name-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístupové údaje a súvislosti s aktuálne načítaným prístupom do dashboard"])},
    "login-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlasovacie meno"])},
    "pass-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístupové heslo"])},
    "pass-conf-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakovať heslo"])},
    "trial-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnosť do"])},
    "active-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívny prístup"])},
    "delete-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať prístup"])},
    "login-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlasovacie meno"])},
    "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do tohto vstupu sa zadáva meno pod ktorým sa správca prihlási do systému"])},
    "login-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 znakov"])},
    "pass-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po mene zadať heslo"])},
    "pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak bolo zadané prihlasovacie meno, potom do tohto vstupu treba zadať heslo pre prihlásenie do dashboard"])},
    "pass-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osem znakov (a-z, A-Z, 0-9) + iný znak"])},
    "pass-conf-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po mene zopakovať heslo"])},
    "pass-conf-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak bolo zadané prihlasovacie meno, potom do tohto vstupu treba zopakovať heslo, ktoré sa musí zhodovať so zadaným vľavo"])},
    "pass-conf-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslá sa musia zhodovať"])},
    "trial-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak je v tomto vstupe zadaný dátum, prístup je alebo bude platný len do tohto dátumu"])},
    "trial-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup je dočasný"])},
    "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne zobrazený prístup možno vymazať, vymazanie je fiktívne, a možno ho v danej chvíli zrušiť"])},
    "active-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne zobrazený prístup možno nastaviť ako aktívny, alebo neaktívny"])},
    "active-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup je aktívny"])},
    "active-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup nie je aktívny"])},
    "delete-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup existuje"])},
    "delete-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup je vymazaný"])},
    "owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazený prístupový účet sa vzťahuje na vlastníka nabíjačky"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať aktuálne údaje k tomuto prístupovému účtu"])}
  },
  "languages": {
    "remote-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálny vzdialený bod"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať jazyk ďalšej krajiny"])},
    "uses-all-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne nastavená nabíjačka používa všetky jazyky, alebo niekde nastala chyba"])},
    "no-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohto dôvodu žiadny jazyk nie je v súčasnosti k dispozícii"])},
    "in-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlajky sa v príslušnej aplikácii vzdialeného bodu budú zobrazovať v poradí v akom sú zobrazené nižšie"])},
    "in-line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk s vlajkou krajiny zobrazenou nižšie na prvom mieste bude považovaný za miestny jazyk vzdialeného bodu"])},
    "in-line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak chcete aktuálne poradie zmeniť, najprv potvrďte vlajku, ktorej umiestnenie potrebujete zmeniť"])},
    "in-line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a potom potvrďte vlajku, ku ktorej má byť vlajka zobrazená na kurzore myšky umiestnená"])},
    "in-line-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlajky môžete zamieňať aj pomocou šípok zobrazených vľavo a vpravo"])},
    "move-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posunúť vlajku"])},
    "move-flag-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smerom vľavo"])},
    "move-flag-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smerom vpravo"])},
    "remove-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť používanie tohto jazyka  v aplikácii vzdialeného bodu"])}
  },
  "top-menu": {
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzdialený bod"])},
    "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklama"])},
    "langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyky"])},
    "connects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konektory"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomoc"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počasie"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup"])}
  },
  "login": {
    "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše prihlasovacie meno"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meno"])},
    "your-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše prihlasovacie heslo"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heslo"])},
    "view-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením tejto ikonky sa zadané heslo zobrazí na tri sekundy"])},
    "enterTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vstúpiť"])},
    "login-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overujem zadané údaje"])},
    "login-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávne údaje pre prihlásenie"])},
    "undef-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastala neidentifikovateľná chyba"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásený"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhláste sa"])},
    "out-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak myškou potvrdíte túto časť, odhlásite sa z tejto aplikácie"])},
    "type": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neznámy typ užívateľa"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervízor"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník nabíjačky"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testér"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vývojár projektu"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup na dobu určitú"])}
    }
  },
  "xxxxxxxxxxxxxxxxxvideo": {
    "first-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte promo-video, ktoré sa spustí na podnet klienta nabíjačky"])}
  },
  "sliders": {
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrať vybraté súbory"])},
    "count-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet nahratých súborov"])},
    "ext-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prípona súboru nie je povolená"])},
    "shot-move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posunúť záber o jeden naspäť"])},
    "shot-move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posunúť záber o jeden dopredu"])},
    "shot-allow-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazovať záber na vzdialenom bode"])},
    "shot-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazovanie tohto záberu je na vzdialenom bode vypnuté"])},
    "shot-view-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Časový limit zobrazenia tohto záberu v sekundách"])},
    "shot-save-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať časový limit zobrazenia záberu - max.99 sekúnd"])},
    "shot-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením ikonky bude aktuálny záber fyzicky vymazaný"])},
    "direct-param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento parameter je povinný"])},
    "view-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet záberov zobrazených na nabíjačke"])},
    "move-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamieňam dva zábery medzi sebou"])},
    "delete-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazávam položku po dvojitom potvrdení vymazania"])},
    "max-limit-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximálny limit zobrazenia je 99 sekúnd"])},
    "limit-allow-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit zobrazenia záberu v sekundách môžete zapísať"])},
    "save-limit-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisujem nastavený limit k potvrdenému záberu"])},
    "set-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdený záber sa nastavuje ako aktívny"])},
    "set-no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdený záber sa nastavuje ako neaktívny"])},
    "load-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybraté súbory načítavam k hore nastavenej nabíjačke"])},
    "out-of-sizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne rozmery obrázka sú 1000px / 320px"])}
  },
  "switch": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÁNO"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIE"])}
  },
  "charger": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte inú aplikáciu na vzdialenom bode"])},
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vzdialený bod"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontakt na"])}
  },
  "xxlang": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať jazyk ďalšej krajiny"])}
  },
  "xxxsetFlags": {
    "line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlajky sa v príslušnej aplikácii vzdialeného bodu budú zobrazovať v poradí v akom sú zobrazené nižšie. "])},
    "line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk s vlajkou krajiny zobrazenou nižšie na prvom mieste bude považovaný za miestny jazyk vzdialeného bodu."])},
    "line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ak chcete aktuálne poradie zmeniť, najprv potvrďte vlajku, ktorej umiestnenie potrebujete zmeniť, "])},
    "line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a potom potvrďte vlajku, ku ktorej má byť vlajka zobrazená na kurzore myšky umiestnená. "])},
    "not-avail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne nastavený vzdialený bod používa všetky jazyky. "])},
    "not-avail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadny ďalší jazyk preto v súčasnosti v tomto okne nie je k dispozíci."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať jazykovú alternatívu"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posunúť vlajku"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smer vľavo"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smer vpravo"])}
  },
  "xxxchargers": {
    "connect-01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia vzdialeného bodu sa ku serveru naposledy pripojila dňa"])},
    "connect-02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["v čase"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať aktuálne informácie ku tejto nabíjačke"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinštalovavať, alebo len reštartovať tenkú aplikáciu na vzdialenom bode"])},
    "note": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručná definícia nabíjačky na vzdialenom bode"])}
    },
    "owner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník nabíjačky s aplikáciou na vzdialenom bode"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno vlastníka"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikácia"])}
    },
    "person": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktná osoba vlastníka nabíjačky s aplikáciou na vzdialenom bode"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
    },
    "wifi": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povoliť / nepovoliť zdieľanú WI-FI na vzdialenom bode "])},
      "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povoliť WI-FI"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásenie k WI-FI"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo k WI-FI"])},
      "title-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosť zadať sprievodný text, ktorý sa môže zobrazovať ak bude WI-FI povolená"])},
      "title-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosť zadať sprievodný text, ktorý sa môže zobrazovať ak WI-FI nebude  povolená"])}
    },
    "other-sets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalšie nastavenia, zobrazenie hodín, časové intervaly v sekundách, spiaci režim a kontrola aktualizácie dát"])},
    "clock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitálne hodiny"])},
    "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minúty - návrat do úvodu aplikacie pri nečinnosti"])},
    "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minúty - kontrola aktualizácie dát na serveri"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívna aplikácia"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte cestu ku turistickej mape, ideálne s API kľúčom a súradnicami umiestnenia nabíjačky"])},
    "error": {
      "note": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základný popis nabíjačky musí mať aspoň 5 znakov"])}
      },
      "restart": {
        "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia na aktuálnom vzdialenom bode bude pri ďalšom pripojení na databázu reštartovaná"])},
        "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novej inštalácii na strane vzdialeného bodu bolo zabránené"])}
      },
      "owner": {
        "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 znakov"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 znakov"])},
        "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 čísiel"])},
        "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 znakov"])},
        "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 3 znaky"])},
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 znakov"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napríklad +421 999 999999"])},
        "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát meno.priezvisko--at--domena.sk"])}
      },
      "wifi": {
        "allow-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI je pri nabíjačke povolená"])},
        "allow-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI pri nabíjačke nie je povolená"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 znakov"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálne 5 znakov"])}
      },
      "digital-clock-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ručičkové hodiny"])},
      "digital-clock-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitálne hodiny"])},
      "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálna hodnota sú 3 (minúty)"])},
      "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimálna hodnota sú 3 (minúty)"])},
      "active-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technická prestávka"])},
      "active-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívna aplikácia"])},
      "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta ku turistickej mape musí byť URL adresa (http://, https:/)"])}
    }
  },
  "select": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberač je momentálne bez ponuky"])}
  },
  "access": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť nového správcu"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať editovaného správcu"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručná charakteristika tohto prístupu"])},
    "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov správcu / vlastníka"])},
    "char-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakteristika oprávnení"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktné číslo na mobil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktná e-mail adresa"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prihlasovacie meno"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prihlasovacie heslo"])},
    "pass-icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť zápis hesla"])},
    "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakujte zápis hesla"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento prístup je aktívny"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup je označený ako vymazaný"])},
    "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial prístup"])},
    "owner-necessary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím vyberte existujúceho alebo vyplňte nového vlastníka nabíjačky"])},
    "owner-chargers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okrem supervísora, vývojára a testéra ostatným typom prístupu sprístupnite konkrétne nabíjačky"])},
    "owner-chargers-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nabíjačku, ku ktorej má byť priradené vlastníctvo pre nastaveného vlastníka"])},
    "error": {
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základný popis prístupového účtu musí mať aspoň 5 znakov"])},
      "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko minimálne 5 znakov + medzera"])},
      "char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakteristiku prístupu treba zadať"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideálny formát čísla"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávny tvar email adresy "])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte aspoň 5 platných znakov"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veľké malé písmená, číslo a iný znak"])},
      "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezhoduje sa so zadaným  heslom vľavo"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup je aktuálne aktívny"])},
      "not-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup je dočasne zablokovanný"])},
      "not-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup nie je označený za vymazaný"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup bol označený za vymazaný"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadaný formát je"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnosť je do zadaného dátumu"])}
    }
  },
  "owner": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup je priradený ku vlastníkovi"])},
    "exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete vybrať existujúceho vlastníka"])},
    "new-alter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alebo vytvoriť nového vlastníka"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť nového vlastníka"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov vlastníka nabíjačky"])},
    "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iná identifikácia vlastníka"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštovné smerové číslo"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa, názov ulice súpisné a radové číslo"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sídlo, obec, mesto - jeho časť"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať zmeny pri vlastníkoch nabíjačky"])},
    "error": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte aspoň 5 platných znakov"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspoň tri platné znaky"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát 99999 alebo 999 99"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napr. Meno ulice 423/1c"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspoň dva znaky, Názov obce - jej časť"])}
    },
    "ship": {
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením pomocou myšky vlastníctvo ku tejto nabíjačke zrušíte"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením pomocou myšky zapnete debugger - zelenú lištu v dolnej časti"])},
      "debug-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením pomocou myšky vypnete zapnutý debugger - zelená lišta v dolnej časti sa nebude zobrazovať"])}
    }
  },
  "weather": {
    "use-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používaný meteorologický systém"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemepisná šírka"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemepisná dĺžka"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "sea-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadmorská výška"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať informácie k predpovedi počasia"])},
    "azimut-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smer vetra (azimut)"])},
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predpoveď počasia nie je v súčasnosti dostupná !"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slnečno"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polojasno"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oblačno"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["takmer zamračené"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["úplne zamračené"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predpoveď počasia po hodinách"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smer vetra"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dážď"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rýchlosť vetra"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nárazy vetra"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlak vzduchu"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sneženie"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["východ slnka"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["západ slnka"])},
    "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedeľa"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondelok"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utorok"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streda"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štvrtok"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piatok"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Január"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Február"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marec"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apríl"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máj"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jún"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Júl"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Október"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "cms": {
    "deposit": {
      "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Práca s CMS obsahom a s jeho depozitom"])},
      "head-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálny obsah tohto CMS môžete odložiť do depozitu, vybrať z iného depozitu, alebo v depozite Vami vytvorený obsah vymazať"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť depozit z tohto CMS obsahu"])},
      "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak potvrdíte túto časť, môžete aktuálny obsah CMS vložiť do depozitu"])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítať depozit do tohto CMS obsahu"])},
      "insert-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z depozitov, ktoré sú aktuálne na serveri k dispozícii môžete načítať obsah do tohto CMS"])},
      "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte z depozitu vytvorenú šablónu názov CMS sekcie - Názvo platformy - UID vzdialeného bodu"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať depozit tohto obsahu"])},
      "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorený depozit vzťahujúci sa na aktuálne CMS môžete kedykoľvek vymazať, aj keď je vhodné mať obsah zachovaný v depozite"])},
      "not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depozit tohto obsahu neexistuje"])},
      "not-exist-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak depozit pre aktuálne CMS neexistuje je vhodné ho zachovať a udržiavať v aktuálnom stave"])},
      "insert-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skutočne si želáte založiť obsah tohto CMS do depozitu ?"])},
      "insert-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah CMS vložiť do depozitu"])},
      "insert-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
      "read-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítať zvolený depozit do tohto CMS "])},
      "read-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
      "delete-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať obsah depozitu vzťahujúci sa na toto CMS ?"])},
      "delete-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať depozit"])},
      "delete-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť na obrazovke"])},
      "preview-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla sa zobrazí obsah aktuálneho CMS v rozmeroch nastavenej obrazovky"])},
      "remove-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať celý obsah CMS"])},
      "remove-all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veľmi dobre si premyslite, či dvojitým potvrdením tohto tlačidla chcete vymazať celý aktuálny obsah CMS"])}
    },
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepáčte, obsah ešte pripravujeme."])},
    "choice-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohto výberu krajín je možné vybrať jazyk, v ktorom sa aktuálne má obsah v rámci celého CMS nástroja manažovať"])},
    "line-move-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V obsahu presunúť aktuálny riadok o jeden vyššie."])},
    "line-move-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V obsahu presunúť aktuálny riadok o jeden nižšie."])},
    "line-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazovať aktuálny riadok v aplikácii."])},
    "line-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálny riadok v aplikácii nezobrazovať."])},
    "line-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeniť nastavenia a parametre aktuálneho riadku."])},
    "line-add-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením na pozíciu tohto riadku pridať ďalší riadok, tento riadok sa zobrazí nižšie."])},
    "copy-style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopírovať riadok s celým príslušenstvom. Pod príslušenstvom  rozumieme štýlové nastavenia riadku, obrázky prislúchajúce ku kopírovanému riadku ak boli nahraté."])},
    "line-trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením zahodiť aktuálny riadok do koša s možnosťou obnovenia."])},
    "line-recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnoviť vymazaný riadok."])},
    "win-line-modal": {
      "close-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponechať vykonané zmeny v riadku a zavrieť okno."])},
      "close-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením myšky zavrieť okno s vykonanými zmenami v nastaveniach riadku."])},
      "close-no-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením myšky zavrieť okno bez zápisu zmien v nastaveniach riadku. Do editovaného riadku sa vráti pôvodné nastavenie."])}
    },
    "image": {
      "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenia pre obrázky."])},
      "img-width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v ľavej časti CMS riadku nachádza obrázok, týmto parametrom môžete upresniť jeho šírku. Treba postupovať tak, aby obrázok nezostal zdeformovaný."])},
      "img-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v ľavej časti CMS riadku nachádza obrázok, týmto parametrom môžete upresniť jeho výšku. Treba postupovať tak, aby obrázok nezostal zdeformovaný."])},
      "img-margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v ľavej časti CMS riadku nachádza obrázok, týmto parametrom môžete nastaviť jeho odsadenie od hornej hranice riadku."])},
      "img-margin-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v ľavej časti CMS riadku nachádza obrázok, týmto parametrom môžete nastaviť jeho odsadenie od pravej hranice riadku."])},
      "img-margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v ľavej časti CMS riadku nachádza obrázok, týmto parametrom môžete nastaviť jeho odsadenie od dolnej hranice riadku."])},
      "img-margin-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v ľavej časti CMS riadku nachádza obrázok, týmto parametrom môžete nastaviť jeho odsadenie od ľavej hranice riadku."])},
      "img-width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v ľavej časti CMS riadku nachádza obrázok, týmto parametrom môžete upresniť jeho šírku. Treba postupovať tak, aby obrázok nezostal zdeformovaný."])},
      "img-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v ľavej časti CMS riadku nachádza obrázok, týmto parametrom môžete upresniť jeho výšku. Treba postupovať tak, aby obrázok nezostal zdeformovaný."])},
      "img-margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v pravej časti CMS riadku nachádza obrázok, týmto parametrom môžete nastaviť jeho odsadenie od hornej hranice riadku."])},
      "img-margin-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v pravej časti CMS riadku nachádza obrázok, týmto parametrom môžete nastaviť jeho odsadenie od pravej hranice riadku."])},
      "img-margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v pravej časti CMS riadku nachádza obrázok, týmto parametrom môžete nastaviť jeho odsadenie od dolnej hranice riadku."])},
      "img-margin-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v pravej časti CMS riadku nachádza obrázok, týmto parametrom môžete nastaviť jeho odsadenie od ľavej hranice riadku."])},
      "image-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týmto nástrojom môžete do ľavej časti nahrať nový obrázok. Existujúci obrázok v ľavej časti bude nahradený. Ak ľavá časť CMS riadku obsahuje text, tento zostane zachovaný a pri vymazaní obrázku sa znova zobrazí."])},
      "image-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týmto nástrojom môžete do pravej časti nahrať nový obrázok. Existujúci obrázok v pravej časti bude nahradený. Ak pravá časť CMS riadku obsahuje text, tento zostane zachovaný a pri vymazaní obrázku sa znova zobrazí."])},
      "upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložiť obrázok do ľavej časti riadku"])},
      "upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vložiť obrázok do pravej časti riadku"])},
      "upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do ľavej časti CMS riadku je okrem textu možné vložiť aj obrázok, ktorý vyberiete z lokálneho počítača. K obrázku je možné určiť šírku výšku a odsadenie od hraníc riadku."])},
      "remove-upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať obrázok z ľavej časti riadku."])},
      "remove-upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať obrázok z pravej časti riadku."])},
      "remove-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v ľavej časti riadku nachádza obrázok, môžete ho dvojitým potvrdením myšky vymazať. Ak ste do ľavej časti v minulosti zadali akýkoľvek text, zostal zachovaný a bude sa opäť v tejto časti zobrazovať."])},
      "remove-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak sa v pravej časti riadku nachádza obrázok, môžete ho dvojitým potvrdením myšky vymazať. Ak ste do pravej časti v minulosti zadali akýkoľvek text, zostal zachovaný a bude sa opäť v tejto časti zobrazovať."])}
    },
    "edit-line": {
      "change-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeniť text"])},
      "change-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením tohto tlačidla bude umožnené vykonávať do textu v ľavej časti CMS riadku zásahy a zmeny."])},
      "save-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapísať"])},
      "change-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením tohto tlačidla bude umožnené vykonávať do textu v pravej časti CMS riadku  zásahy a zmeny."])},
      "copy-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri používaní CMS (obsahového manažmentu) pre uľahčenie práce je tu v ľavej časti riadku možnosť kopírovať už vypracovaný text z iného jazyka v tom istom riadku a v tej istej jeho časti - teda z inej jazykovej verzie aktuálneho obsahu CMS."])},
      "copy-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri používaní CMS (obsahového manažmentu) pre uľahčenie práce je tu v pravej časti riadku možnosť kopírovať už vypracovaný text z iného jazyka v tom istom riadku a v tej istej jeho časti - teda z inej jazykovej verzie aktuálneho obsahu CMS."])},
      "save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla budú všetky zmeny zapísané a režim editovania textu ukončený. Ak stlačíte ikonu odpadkového koša úplne vľavo hore, zapísané zmeny včetne textového obsahu budú vynulované."])},
      "no-save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením ikony s odpadkovým košom bude režim editovania textu ukončený bez zápisu zmien."])},
      "no-save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením ikony s odpadkovým košom bude režim editovania textu ukončený bez zápisu zmien."])},
      "save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stlačením tohto tlačidla budú všetky zmeny zapísané a režim editovania textu ukončený. Ak stlačíte ikonu odpadkového koša úplne vľavo hore, zapísané zmeny včetne textového obsahu budú vynulované."])},
      "change-l-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zameniť ľavú a pravú časť riadku medzi sebou."])},
      "width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte šírku ľavej časti riadku, minimálne 1% / max. 99%."])},
      "width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte šírku pravej časti riadku, minimálne 1% / max. 99%."])},
      "no-view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením ikony nastaviť, aby sa ľavá časť riadku nezobrazovala, pravá časť bude zaberať celý riadok."])},
      "view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením ikony nastaviť, aby sa ľavá časť riadku zobrazovala, šírka obidvoch častí riadku bude inicializovaná na 50%."])},
      "no-view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením ikony nastaviť, aby sa pravá časť riadku nezobrazovala, ľavá časť bude zaberať celý riadok."])},
      "view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením ikony nastaviť, aby sa pravá časť riadku zobrazovala, šírka obidvoch častí riadku bude inicializovaná na 50%."])},
      "disabled-view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V konkrétnom riadku nie je možné vypnúť zobrazenie všetkých jeho častí."])},
      "height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade potreby si môžete nastaviť aj výšku ľavej časti riadku. Výška nesmie byť menej ako 50px a tiež nesmie presahovať celkovú výšku riadku."])},
      "height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade potreby si môžete nastaviť aj výšku pravej časti riadku. Výška nesmie byť menej ako 50px a tiež nesmie presahovať celkovú výšku riadku."])},
      "font-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohto vyberača je možné nastaviť iný font, ktorý má byť v ľavej časti riadku použitý. Ak potrebujete pridať úplne nový font, obráťte sa s požiadavkou na najvyššieho administrátora."])},
      "font-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z tohto vyberača je možné nastaviť iný font, ktorý má byť v pravej časti riadku použitý. Ak potrebujete pridať úplne nový font, obráťte sa s požiadavkou na najvyššieho administrátora."])},
      "color-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myškou sa nachádzate na možnosti meniť farbu textu v ľavej časti riadku. Hodnota musí byť vždy v hexadecimálnom tvare (šestnástková sústava) s tromi lebo šiestimi znakmi (1 až 9, A až F), inak je neplatná."])},
      "color-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myškou sa nachádzate na možnosti meniť farbu textu v pravej časti riadku. Hodnota musí byť vždy v hexadecimálnom tvare  (šestnástková sústava) s tromi lebo šiestimi znakmi (1 až 9, A až F), inak je neplatná."])},
      "font-size-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota dáva možnosť zmeny veľkosti písma v ľavej časti riadku. Z množstva možností tento CMS používa jednotku em, pretože je relatívna s dobrý použitím pri responzívnom zobrazovaní. Povolené 0.5 až 4.9(em)."])},
      "font-size-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota dáva možnosť zmeny veľkosti písma v pravej časti riadku. Z množstva možností tento CMS používa jednotku em, pretože je relatívna s dobrý použitím pri responzívnom zobrazovaní. Povolené 0.5 až 4.9(em)."])},
      "font-weight-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V ľavej časti editovaného riadku je možnosť nastaviť aj hrúbku používaného písma. Aplikácie sú vyvinuté tak, aby hrúbku písma bolo možné nastaviť čo najcitlivejšie. "])},
      "font-weight-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V pravej časti editovaného riadku je možnosť nastaviť aj hrúbku používaného písma. Aplikácie sú vyvinuté tak, aby hrúbku písma bolo možné nastaviť čo najcitlivejšie. "])},
      "font-style-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo sa týka štýlu písma, v ľavej časti riadku môžete nastaviť normálne písmo, alebo kurzívu (italic). Iné možnosti boli z dôvodu nízkej kompatibility medzi fontmi odignorované."])},
      "font-style-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo sa týka štýlu písma, v pravej časti riadku môžete nastaviť normálne písmo, alebo kurzívu (italic). Iné možnosti boli z dôvodu nízkej kompatibility medzi fontmi odignorované."])},
      "background-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento manažment obsahu umožňuje zmeniť farbu pozadia aj len v ľavej časti editovaného riadku. Hodnota musí byť vždy v hexadecimálnom tvare  (šestnástková sústava) s tromi lebo šiestimi znakmi (1 až 9, A až F), inak je neplatná. Farba len pre časť riadku nemusí byť nutne zadaná."])},
      "background-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento manažment obsahu umožňuje zmeniť farbu pozadia aj len v pravej časti editovaného riadku. Hodnota musí byť vždy v hexadecimálnom tvare  (šestnástková sústava) s tromi lebo šiestimi znakmi (1 až 9, A až F), inak je neplatná. Farba len pre časť riadku nemusí byť nutne zadaná."])},
      "line-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri editovaní ľavej časti riadku je možnosť nastaviť aj výšku odsadenia textu medzi textovými riadkami. Výsledkom bude odsadenie jednotlivých textových riadkov od seba na výšku. Túto hodnotu nie je potrebné zadávať."])},
      "line-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri editovaní pravej časti riadku je možnosť nastaviť aj výšku odsadenia textu medzi textovými riadkami. Výsledkom bude odsadenie jednotlivých textových riadkov od seba na výšku. Túto hodnotu nie je potrebné zadávať."])},
      "text-align-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V ľavej časti riadku máme možnosť nastaviť aj vodorovné odsadenie textu. left - znamená zarovnať text vľavo, right - znamená zarovnať text vpravo, center - znamená zarovnať text na stred a justify - znamená zarovnať text rovnomerne na celú šírku elementu."])},
      "text-align-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V pravej časti riadku máme možnosť nastaviť aj vodorovné odsadenie textu. left - znamená zarovnať text vľavo, right - znamená zarovnať text vpravo, center - znamená zarovnať text na stred a justify - znamená zarovnať text rovnomerne na celú šírku elementu."])},
      "text-decor-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako bonus môžete text v ľavej časti ešte aj podčiarknuť voľbou underline. Ak zadáte none, text nebude podčiarknutý."])},
      "text-decor-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako bonus môžete text v pravej časti ešte aj podčiarknuť voľbou underline. Ak zadáte none, text nebude podčiarknutý."])},
      "padding-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnoty popri tejto ikonke slúžia na odsadenie textu vo vnútri ľavej časti editovaného riadku. Viac informácii sa zobrazí ak nastavíte kurzor myšky na konkrétnu hodnotu v priamej blízkosti ikonky."])},
      "margin-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnoty popri tejto ikonke slúžia na odsadenie ľavej časti editovaného riadku zhora alebo zdola. Zľava alebo odprava nie je možné vnútornú časť riadku odsadiť.  Viac informácii sa zobrazí ak nastavíte kurzor myšky na konkrétnu hodnotu v priamej blízkosti ikonky."])},
      "margin-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnoty popri tejto ikonke slúžia na odsadenie pravej časti editovaného riadku zhora alebo zdola. Zľava alebo odprava nie je možné vnútornú časť riadku odsadiť.  Viac informácii sa zobrazí ak nastavíte kurzor myšky na konkrétnu hodnotu v priamej blízkosti ikonky."])},
      "padding-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnoty popri tejto ikonke slúžia na odsadenie textu vo vnútri pravej časti editovaného riadku. Viac informácii sa zobrazí ak nastavíte kurzor myšky na konkrétnu hodnotu v priamej blízkosti ikonky."])},
      "padding-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť text v ľavej časti od jej hornej hranice odsadený. Ak je hodnota prázdna, žiadne odsadenie zhora nie je nastavené."])},
      "margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť ľavá časť riadku odsadená smerom od hora hranice riadku. Ak je hodnota prázdna, žiadne odsadenie zhora nie je nastavené."])},
      "margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť pravá časť riadku odsadená smerom od hora hranice riadku. Ak je hodnota prázdna, žiadne odsadenie zhora nie je nastavené."])},
      "margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť ľavá časť riadku odsadená smerom zdola hranice riadku. Ak je hodnota prázdna, žiadne odsadenie zdola nie je nastavené."])},
      "margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť pravá časť riadku odsadená smerom zdola hranice riadku. Ak je hodnota prázdna, žiadne odsadenie zdola nie je nastavené."])},
      "padding-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť text v ľavej časti od jej pravej hranice odsadený. Ak je hodnota prázdna, žiadne odsadenie z pravej strany nie je nastavené."])},
      "padding-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť text v ľavej časti od jej dolnej hranice odsadený. Ak je hodnota prázdna, žiadne odsadenie z dolnej strany nie je nastavené."])},
      "padding-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť text v ľavej časti od jej ľavej hranice odsadený. Ak je hodnota prázdna, žiadne odsadenie z ľavej strany nie je nastavené."])},
      "padding-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť text v pravej časti od jej hornej hranice odsadený. Ak je hodnota prázdna, žiadne odsadenie zhora nie je nastavené."])},
      "padding-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť text v pravej časti od jej pravej hranice odsadený. Ak je hodnota prázdna, žiadne odsadenie z pravej strany nie je nastavené."])},
      "padding-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť text v pravej časti od jej dolnej hranice odsadený. Ak je hodnota prázdna, žiadne odsadenie z dolnej strany nie je nastavené."])},
      "padding-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto hodnota určuje, o koľko pixelov (bodov) má byť text v pravej časti od jej ľavej hranice odsadený. Ak je hodnota prázdna, žiadne odsadenie z ľavej strany nie je nastavené."])},
      "height-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastaviť výšku celého editovaného riadku. Nemala by byť menšia ako 50 pixelov (bodov) a vyššia ako 1500 pixelov (bodov)."])},
      "background-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touto hodnotou je možné nastaviť farbu pozadia celého riadku. Hodnota musí byť vždy v hexadecimálnom tvare (šestnástková sústava) s tromi lebo šiestimi znakmi (1 až 9, A až F), inak je neplatná."])},
      "margin-glob-icon-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnotou vedľa ikony vpravo je možné nastaviť odsadenie vnútorných kontajnerov riadku od hornej hranice riadku. V tomto kontexte odporúčame použiť aj nastavenie celkovej výšky riadku, čím sa dá dosiahnuť odsadenie aj od dolnej časti riadku."])},
      "margin-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touto hodnotou je možné nastaviť odsadenie vnútorných kontajnerov riadku od hornej hranice riadku. V tomto kontexte odporúčame použiť aj nastavenie celkovej výšky riadku, čím sa dá dosiahnuť odsadenie aj od dolnej časti riadku."])}
    },
    "view-delete": {
      "text-date-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riadok vymazaný v čase"])},
      "active-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť iba aktívne riadky"])},
      "active-only-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením tohto tlačidla sa zobrazia aj CMS riadky, ktoré boli označené ako vymazané."])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť aj vymazané riadky"])},
      "all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením tohto tlačidla sa zobrazia aj CMS riadky, ktoré boli označené ako vymazané"])},
      "drop-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením logicky vymazaný CMS riadok bude deifinitívne odstránený , toto zmazanie už nemožno vrátiť späť."])},
      "restore-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojitým potvrdením logicky vymazaný CMS riadok bude vrátený späť medzi riadky, ktoré neboli označené ako vymazané."])}
    }
  }
}