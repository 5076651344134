export default {
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich suche ein Lademodul für meine Marke – mindestens 3 Zeichen"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anwendung kann ein Lademodul finden, das mit der Marke Ihres Geräts kompatibel ist"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erst nach der Eingabe von mindestens drei Zeichen beginnt die Anwendung mit der Suche nach einem kompatiblen Lademodul"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen gesuchte elektrische Maschine ist derzeit nicht im Anwendungskatalog zu finden"])},
    "sub-menu": {
      "e-machines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lademaschinen"])},
      "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lademodule – Kompatibilitätseinstellung"])},
      "connectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichten der Module für das Ladegerät"])}
    },
    "set-params": {
      "button-previev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau der Lademodule mit vorübergehenden Änderungen in Panels anzeigen, um noch nicht geschriebene Änderungen durchzuführen"])},
      "button-previev-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche (sofern aktiv mit blauem Hintergrund) werden die Ladeanschlussmodule unten mit einer neuen Einstellung angezeigt, die noch nicht in die Datenbank geschrieben wurde"])},
      "button-no-previev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren Sie die in den Panels vorgenommenen Änderungen für die Ausführung von Änderungen und eine Vorschau dieser Änderungen und kehren Sie zum aktuellen Zustand in der Datenbank zurück"])},
      "button-no-previev-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche (sofern aktiv mit blauem Hintergrund) wird die angezeigte Vorschau der Lademodule abgebrochen und der Status aus der Datenbank angezeigt"])},
      "set-brand-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von diesem Selektor aus ist es möglich, die Anschlussnummer auszuwählen"])},
      "set-brand-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordnen Sie die Marke zu, die Sie im Katalog der Lademodule finden"])},
      "set-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie das Lademodul ein"])},
      "current-brand-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Modulmarke"])},
      "modul-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen Sie sich dieses Modul an"])},
      "modul-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit diesem Schalter kann eingestellt werden, ob das Modul eine Nummer hat"])},
      "modul-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige auf dem Display. Der empfohlene Wert ist JA."])},
      "machine-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompatible Maschinen anzeigen"])},
      "machine-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über diesen Schalter kann eingestellt werden, ob beim Öffnen des Moduls eine Nummer angezeigt wird"])},
      "machine-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie auch Bilder oder Marken kompatibler elektrischer Maschinen an. Der empfohlene Wert ist NEIN."])},
      "images-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Bikes anzeigen"])},
      "images-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über diesen Schalter kann eingestellt werden, ob beim Öffnen des Moduls eine Nummer angezeigt wird"])},
      "images-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie auch Bilder kompatibler elektrischer Maschinen an. Der empfohlene Wert ist NEIN, es sei denn, Sie laden auch Bilder von elektrischen Maschinen hoch."])},
      "brand-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marken von E-Bikes anzeigen"])},
      "brand-view-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über diesen Schalter kann eingestellt werden, ob beim Öffnen des Moduls eine Nummer angezeigt wird"])},
      "brand-view-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie auch Marken kompatibler Elektromaschinen an. Der eingestellte Wert ist NEIN, es liegt am Eigentümer, zu entscheiden, ob er die Anzeige kompatibler Marken anfordern möchte."])},
      "margin-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrückung dieses Moduls"])},
      "margin-left-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese immer nur numerischen Daten gewährleisten den Offset des Zahlenmoduls"])},
      "margin-left-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auf dem Display in Bildschirmpunkten, sogenannten Pixeln, vom linken Rand des Displays. Wir empfehlen Ihnen, die Änderung dieser Daten zunächst sorgfältig abzuwägen."])},
      "button-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie die Daten in diese Spalte"])},
      "button-save-title-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn die Schaltfläche aktiv ist (mit blauem Hintergrund), ändert sich nach dem Drücken die Nummer des Lademoduls"])},
      "button-save-title-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["werden in die Datenbank geschrieben"])}
    },
    "e-machines": {
      "new-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie dem Katalog eine weitere elektrische Maschine hinzu"])},
      "new-line-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche können Sie dem Katalog eine weitere elektrische Lademaschine hinzufügen"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie weitere Fotos von elektrischen Maschinen hoch"])},
      "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über diesen Button können neue Fotos anderer Elektrofahrzeuge in das System eingefügt werden"])},
      "find-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchsuchen Sie die Kette nach Marken für elektronische Maschinen"])},
      "find-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eine Zeichenfolge ein, um die Anzeige elektrischer Maschinen zu filtern"])},
      "find-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Eingabe einer Zeichenfolge in diese Eingabe wird der Anzeigefilter für elektrische Maschinen gestartet – es werden nur die Marken angezeigt, deren Name die eingegebene Zeichenfolge enthält"])},
      "remove-filter-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch doppeltes Bestätigen dieser Schaltfläche heben Sie den aktuell eingestellten Anzeigefilter für elektrische Maschinen auf"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marke des Geräts zum Laden über den Anschluss"])},
      "brand-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markenkürzel"])},
      "set-brand-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Maschinenmarke für das elektrische Laden ein"])},
      "set-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch für den Einsatz eines Filters in einer Anwendung an einer entfernten Stelle ist es beispielsweise ideal, in dieser Eingabe die gesamte Marke mit der Typenbezeichnung der Maschine einzugeben"])},
      "set-short-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markenkürzel"])},
      "set-short-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Verknüpfung wird über dem physischen Ladegerätanschluss angezeigt, wenn das Gerät zum Anschluss gehört"])},
      "set-brand-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Marke des Ladegeräts muss mindestens 3 gültige Zeichen enthalten"])},
      "set-short-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])},
      "button-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben"])},
      "button-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drücken Sie diese Taste, um die an der Marke dieser Maschine vorgenommenen Änderungen zu speichern"])},
      "offer-in-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot in der App"])},
      "view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das Symbol mit einem Auge grün ist, befindet sich dieser Ständer in der Anwendung in der Nähe der Anschlüsse im Menü zum Laden"])},
      "hidden-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das Symbol orange mit einem durchgestrichenen Auge ist, ist dieser Ladeständer in der Anwendung in der Nähe der Anschlüsse nicht verfügbar"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Maschine wurde kürzlich gelöscht, daher ist es nicht möglich, sie so einzustellen, dass sie in der Anwendung auf dem Remote-Punkt angezeigt wird"])},
      "remove-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
      "remove-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "remove-really": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "remove-click-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch doppeltes Bestätigen dieses Symbols markieren Sie die aktuelle Maschine als gelöscht"])},
      "not-remove-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Löschen einer Elektromaschine, die einem beliebigen Ladeanschluss zugeordnet ist, ist nicht möglich"])},
      "is-remove-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miteinander ausgehen"])},
      "is-remove-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diese Maschine wurde gelöscht. Wenn Sie den Löschvorgang nicht durch eine doppelte Bestätigung mit der Maus abbrechen, wird das System diese Maschine innerhalb der nächsten drei Tage auch physisch und dauerhaft löschen"])},
      "is-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
      "con-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist dieser elektrischen Maschine zugeordnet"])},
      "con-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktiv"])},
      "con-no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inaktiv"])},
      "con-remove-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis zur Löschung"])},
      "con-no-remove-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wartet nicht darauf, gelöscht zu werden"])},
      "con-not-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser elektrischen Maschine ist kein Lademodul zugeordnet"])}
    },
    "modules": {
      "new-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie dem Katalog einen weiteren Connector hinzu"])},
      "new-line-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche können Sie dem Katalog einen neuen Stecker hinzufügen – ein Modul am Ladegerät"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie Fotos mit zusätzlichen Anschlüssen hoch"])},
      "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche können neue Fotos anderer Anschlüsse zum System hinzugefügt werden"])},
      "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen Sie nach Zeichenfolgen in Connector-Modul-Tags"])},
      "find-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eine Zeichenfolge ein, um die Anzeige der Ladeanschlussmodule zu filtern"])},
      "find-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Eingabe einer Zeichenfolge in diese Eingabe wird der Anzeigefilter der Ladeanschlussmodule gestartet – es werden nur die Module angezeigt, deren Name die angegebene Zeichenfolge enthält"])},
      "remove-filter-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch doppeltes Bestätigen dieser Schaltfläche heben Sie den aktuell eingestellten Anzeigefilter der Ladeanschlussmodule auf"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marke des Ladegerätsteckers"])},
      "brand-short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markenkürzel"])},
      "set-brand-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Marke des Anschlussmoduls zum Laden ein"])},
      "set-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch für den Einsatz einer Steckerbeschriftung oder eines Filters in einer Anwendung an einer entfernten Stelle ist es beispielsweise ideal, in dieser Eingabe die gesamte Marke mit der Typenbezeichnung des Steckermoduls einzutragen"])},
      "set-short-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusamenfassend"])},
      "set-short-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Abkürzung der in der Texteingabe links aufgeführten Marke ein, so wie sie in der Anwendung über dem physischen Anschluss des Ladegeräts angezeigt wird"])},
      "set-brand-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Tag muss mindestens 3 gültige Zeichen enthalten"])},
      "set-short-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 3 Zeichen"])},
      "button-brand-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche werden die am Modul-Tag dieses Anschlusses vorgenommenen Änderungen gespeichert"])},
      "button-brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben"])},
      "offer-in-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot in der App"])},
      "view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das Augensymbol grün ist, aktiviert die Anwendung dieses Ladeanschlussmodul im Menü auf dem Monitor"])},
      "hidden-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das Symbol orange mit einem durchgestrichenen Auge ist, zeigt die Anwendung das Modul dieses Anschlusses nicht auf dem Monitor an"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Connector-Modul wurde kürzlich gelöscht, daher ist es nicht möglich, es so einzustellen, dass es in der Anwendung auf dem Remote-Punkt angezeigt wird"])},
      "remove-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
      "remove-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "remove-really": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "remove-click-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch doppeltes Bestätigen dieses Symbols markieren Sie das aktuelle Anschlussmodul als gelöscht"])},
      "not-remove-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nicht möglich, einen Anschluss zu löschen, an den mindestens eine elektrische Maschine gebunden ist"])},
      "is-remove-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miteinander ausgehen"])},
      "is-remove-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Anschlussmodul wurde gelöscht. Wenn Sie den Löschvorgang nicht durch eine erneute doppelte Bestätigung der Maus abbrechen, wird das System dieses Anschlussmodul innerhalb der nächsten drei Tage auch physisch und endgültig löschen"])},
      "is-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
      "machines-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist möglich, elektrische Maschinen anzuschließen, deren Liste unten aufgeführt ist"])},
      "new-choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie in dieser Zeile ein anderes Ladegerät aus, das mit dem Anschluss kompatibel ist"])},
      "new-choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Menü enthält alle elektrischen Maschinen, die nicht als kompatibel mit dem in diesem Abschnitt gezeigten Anschluss gekennzeichnet sind. Durch Ankreuzen einer anderen Maschine in dieser Auswahl wird diese Maschine als kompatibel mit dem oben genannten Ladeanschlussmodul hinzugefügt"])},
      "with-connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Anschlussmodul"])},
      "click-remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch erneutes Ankreuzen dieses Symbols heben Sie die Kompatibilität des in diesem Abschnitt gezeigten Elektrogeräts und des Ladeanschlussmoduls auf            "])}
    }
  },
  "remote": {
    "condition-as-serialno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungen nach Nummer sortiert"])},
    "condition-as-serialno-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche erhalten Sie eine Tabelle mit dem Status der Anwendungen an entfernten Punkten, sortiert nach ihrer Nummer, oder eine Liste der derzeit verfügbaren Installationen. Wenn Sie keine Supervisor-Rechte haben, werden nur Remote-Punkte angezeigt, deren Eigentümer Sie sind"])},
    "condition-as-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendungen nach Beschreibung sortiert"])},
    "condition-as-note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche erhalten Sie eine Tabelle mit dem Status der Anwendung an entfernten Punkten, sortiert nach der Beschreibung der Orte, an denen sie sich befinden. Wenn Sie keine Supervisor-Rechte haben, werden nur Remote-Punkte angezeigt, deren Eigentümer Sie sind"])},
    "current-serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell angezeigtes Ladegerät"])},
    "sort-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Auswahl an Zusatzladegeräten ist sortierbar"])},
    "sort-serialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Ladegeräte"])},
    "sort-or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
    "sort-not-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladegeräte ohne Besitzer"])},
    "sort-can-be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können nach sortiert werden"])},
    "sort-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladegerätnamen"])},
    "read-new-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie die aktuellen Remote-Punkt-Einstellungen"])},
    "read-new-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn auf einem anderen Computer Änderungen am aktuellen oder einem anderen Remote-Punkt vorgenommen werden, werden durch Drücken dieser Schaltfläche alle Änderungen geladen und die Anzeige der Einstellungen des Remote-Punkts aktualisiert"])},
    "not-owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie besitzen keine Anwendung auf dem Remote-Punkt"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie aktuelle Informationen zu diesem Ladegerät ein"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine kurze Definition eines Remote-Point-Ladegeräts"])},
    "error-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kurzbeschreibung des Ladegeräts muss mindestens 5 Zeichen enthalten"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Remote-Punktanwendung, die zuletzt mit dem Server verbunden war"])},
    "err-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Datum oder Uhrzeit auf dem Ladegerät"])},
    "in-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Zeitpunkt"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installieren Sie die Thin-Anwendung auf dem Remote-Punkt neu oder starten Sie sie einfach neu"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Besitzer des Ladegeräts mit der Remote-Point-Anwendung"])},
    "owner-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name oder Titel"])},
    "owner-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name der natürlichen oder juristischen Person des Eigentümers des Ladegeräts"])},
    "owner-street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße mit Hausnummer"])},
    "owner-street-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße und Hausnummer, die Teil der Adresse des Besitzers des Ladegeräts sind"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Postleitzahl der Adresse des Ladegerätebesitzers"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptquartier"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnsitz des Besitzers des Ladegeräts"])},
    "identify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikation"])},
    "identify-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifizierung des Besitzers des Ladegeräts"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner des Besitzers des Ladegeräts mit der Anwendung am entfernten Punkt"])},
    "person-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name und Nachname"])},
    "person-name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vor- und Nachname der Person, die im Zusammenhang mit dem Ladegerät kontaktiert werden kann"])},
    "person-mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "person-mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer des Ansprechpartners für Ladegeräte"])},
    "person-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "person-mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische Adresse des Ansprechpartners für das Ladegerät"])},
    "wifi-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsames WLAN am Remote-Punkt aktivieren/deaktivieren"])},
    "wifi-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich bei WIFI an"])},
    "wifi-login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn der Besitzer des Ladegeräts die Freigabe des WLAN-Netzwerks gestattet hat, müssen Sie hier den Benutzernamen eingeben, um sich anzumelden"])},
    "wifi-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN Passwort"])},
    "wifi-pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn der Besitzer des Ladegeräts die Freigabe des WLAN-Netzwerks erlaubt hat, geben Sie hier das Login-Passwort ein"])},
    "wifi-is-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am Remote-Punkt ist WLAN aktiviert"])},
    "wifi-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN ist am Remote-Punkt nicht aktiviert"])},
    "wifi-login-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])},
    "wifi-pass-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Einstellungen, Uhrzeitanzeige, Zeitintervalle in Sekunden, Schlafmodus und Datenaktualisierungsprüfung"])},
    "digital-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitaluhr"])},
    "global-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehren Sie im Leerlauf zum Start der Anwendung zurück"])},
    "control-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung der Datenaktualisierung auf dem Server"])},
    "active-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Bewerbung"])},
    "global-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit in Minuten"])},
    "global-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier die Zeit in Minuten ein, die den Zeitraum ausdrückt, nach dem die Anwendung mit der eingestellten Uhr zum Start zurückkehrt, wenn keine Aktivität stattfindet"])},
    "control-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit in Minuten"])},
    "control-set-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier die Zeit in Minuten ein, die das Intervall angibt, nach dem die Anwendung auf dem Ladegerät eine Datenaktualisierungsprüfung auf dem Server durchführen soll, die über dieses Dashboard durchgeführt wird"])},
    "digital-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitaluhr in der Einleitung"])},
    "analog-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analoguhr in der Einleitung"])},
    "global-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Grenze liegt zwischen 1 und 999 Minuten"])},
    "control-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Grenze liegt zwischen 1 und 999 Minuten"])},
    "actice-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ladeanwendung ist aktiv"])},
    "actice-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ladeanwendung ist nicht aktiv"])},
    "map-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den HTTP-/HTTPS-Pfad zur Touristenkarte ein"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Route in die Wanderkarte ein, idealerweise mit dem API-Schlüssel und den Koordinaten des Ladestandorts"])},
    "map-path-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den HTTP-/HTTPS-Pfad zur Touristenkarte ein, wenn die Karte nicht öffentlich mit einem API-Schlüssel ist. Achten Sie auf Werbung und andere Links auf der Karte"])},
    "map-path-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Pfad zur Touristenkarte muss eine URL-Adresse sein (http://, https://)"])},
    "promo-video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ein Werbevideo ein, das auf Wunsch des Ladekunden abgespielt wird"])},
    "promo-video-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das Promo-Video nicht angegeben ist, spielt die Anwendung das Video ab, das sie als Standard markiert hat"])},
    "archa-create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein Datenarchiv mit Anhängen zum aktuellen Ladegerät"])},
    "archa-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie das Datenarchiv mit Anhängen von diesem Ladegerät herunter"])},
    "at-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erstellt am"])},
    "at-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Zeitpunkt"])},
    "in-windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["direkt im Windows-Dateibrowser kann es sein, dass der Inhalt der ...zip-Datei nicht korrekt angezeigt wird"])},
    "archa-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über diesen Link ist es möglich, Archivdaten mit Anhängen auf das aktuelle Ladegerät herunterzuladen – verwenden Sie dazu das entsprechende ZIP-Tool, da das Windows-System mit dem heruntergeladenen Paket möglicherweise nicht richtig funktioniert"])}
  },
  "shots": {
    "current-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der aktuell eingestellte Remote-Punkt"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie die Bilder hoch, die zum festgelegten Zeitpunkt unten in der Anwendung angezeigt werden sollen"])},
    "upload-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im unteren Teil des Anwendungsbildschirms besteht an einem entfernten Punkt die Möglichkeit, eine große Anzahl von Bildern auf einmal von der lokalen Festplatte hochzuladen, die Sie zusätzlich so einstellen können, dass sie innerhalb der Zeitvorgaben realistisch in der Anwendung angezeigt werden"])},
    "move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschieben Sie das aktuelle Bild in der Reihenfolge der Werbebilder am festgelegten Remote-Punkt um eins nach hinten"])},
    "set-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Adresse der WEB-Seite ein. Wenn diese in der Anwendung angezeigt werden soll, wird nach Bestätigung dieses Bildes mit der Maus die Seite ohne Verwendungsmöglichkeit angezeigt"])},
    "set-url-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Adresse der WEB-Seite ein (z. B. https://nazov-stranky.com oder http://nazov-stranky.com, wenn die Seite kein SSL verwendet)"])},
    "set-url-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Eintrag besteht die Möglichkeit, den Namen der zu öffnenden Seite einzugeben und der Client an der Gegenstelle bestätigt dieses Bild mit der Maus. Die Seite wird angezeigt, ohne dass Sie sie in der Anwendung weiter verwenden können. Wenn Sie die Seitenadresse nicht eingeben, wird nach Bestätigung mit der Maus kein Vorgang aufgerufen"])},
    "url-err-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die URL-Adresse der WEB-Seite muss mit http:// beginnen, im SSL-Protokoll https://"])},
    "url-err-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Domain der ersten Ebene (com, tech, info...) muss durch einen Punkt am Ende der URL-Adresse getrennt werden"])},
    "url-err-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Domain der ersten Ebene (com, tech, info...) kann maximal 5 Zeichen lang sein"])},
    "other-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Einstellungen für das aktuelle Bild, seine Anzeige in der Anwendung, Anzeigedauer in Sekunden oder physisches Löschen dieses Bildes"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie dieses Symbol durch zweimaliges Drücken der Maustaste bestätigen, wird das aktuelle Werbebild dauerhaft physisch gelöscht"])},
    "limit-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigelimit"])},
    "limit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dieser Eingabe besteht die Möglichkeit, einen Wert in Sekunden einzugeben, wie viele Sekunden dieses Werbebild innerhalb der Gruppe angezeigt werden soll – (die maximal mögliche einstellbare Zeit beträgt bis zu 999 Sekunden)"])},
    "button-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die URL und das Anzeigelimit ein"])},
    "set-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit diesem Icon lässt sich mit der Maus einstellen, ob dieses Werbebild in der Anwendung angezeigt werden soll (grünes Icon mit Auge) oder nicht (oranges Icon mit durchgestrichenem Auge)"])},
    "move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschieben Sie das aktuelle Bild in der Reihenfolge der Werbebilder am festgelegten Remote-Punkt um eins nach vorne"])}
  },
  "owners": {
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen vorhandenen Eigentümer aus, um ihn anzuzeigen oder Änderungen vorzunehmen"])},
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können in dieser Auswahl jeden Eigentümer auswählen oder danach suchen, sofern Sie dazu berechtigt sind"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen neuen Ladegerätebesitzer"])},
    "name-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name des Besitzers des Ladegeräts am entfernten Punkt, seine Identifikation"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name oder Name des Eigentümers"])},
    "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige Eigentümeridentifikation"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "name-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name oder Name des Eigentümers"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Eintrag ist es möglich, den Namen oder Titel des Besitzers einzugeben oder zu bearbeiten oder den Namen oder Titel des neuen Besitzers des Ladegeräts einzugeben"])},
    "ident-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Identifikation"])},
    "ident-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Eintrag ist es möglich, eine andere Identifikation des Eigentümers einzugeben oder zu bearbeiten. Dabei kann es sich um eine Organisationsidentifikationsnummer, aber auch um eine beliebige andere Nummer oder alphanumerische Zeichenfolge handeln"])},
    "zip-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "zip-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Eintrag wird die Postleitzahl eingetragen"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie mindestens 5 gültige Zeichen ein"])},
    "ident-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie mindestens 3 gültige Zeichen ein"])},
    "zip-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das empfohlene Format ist 999 99"])},
    "address-title-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse und Sitz des Besitzers des Ladegeräts am entfernten Punkt"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße und Hausnummer innerhalb des Wohnsitzes des Eigentümers"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnsitz des Eigentümers"])},
    "address-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße und Hausnummer"])},
    "address-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Eintrag ist es möglich, die Adresse des Eigentümers zu bearbeiten bzw. einzugeben, d. h. die Straße und Hausnummer des Eingangs, an dem der Eigentümer wohnt"])},
    "seat-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnsitz des Eigentümers"])},
    "seat-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Eintrag ist es möglich, den Wohnsitz des Eigentümers, die Stadt und ihren Stadtteil oder den Namen des Dorfes einzugeben oder zu bearbeiten"])},
    "address-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Adresse muss korrekt eingegeben werden"])},
    "address-warn-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Adresse muss den Namen der Straße und die Nummer der Einfahrt enthalten"])},
    "address-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Straßenname beginnt mit einem Großbuchstaben"])},
    "address-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unspezifische Nummerierung des Eingangs"])},
    "address-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die genaue Kennzeichnung der Eingangsnummer fehlt"])},
    "seat-warn-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Sitz des Eigentümers muss die Stadt oder Gemeinde eingetragen werden"])},
    "seat-warn-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name der Stadt oder des Dorfes muss mit einem Großbuchstaben beginnen"])},
    "seat-warn-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name enthält ungültige Zeichen"])},
    "seat-warn-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Stadt oder Gemeinde muss mindestens zwei Zeichen haben"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie aktuelle Daten für diesen Eigentümer ein"])},
    "ship": {
      "choice-charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie das Ladegerät aus, dem Sie den Besitz zuweisen möchten"])},
      "choice-charger-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dieser Auswahl können Sie ein Ladegerät auswählen oder suchen, das noch keinen Besitz hat – durch die Auswahl eines Ladegeräts wird diesem der Besitz für den aktuell eingestellten Besitzer zugewiesen"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentum kündigen"])},
      "charger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladegerätnummer mit ihrer Textdefinition"])},
      "debug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsleiste"])},
      "remove-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch einen Doppelklick mit der Maus kündigen Sie den Besitz des rechts aufgeführten Ladegeräts"])},
      "remove-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht berechtigt, den Besitz des Ladegeräts zu widerrufen"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch einen Doppelklick auf das links abgebildete Ladegerät schalten Sie den unteren grünen Balken zum Debuggen der Anwendung ein"])},
      "debug-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch einen Doppelklick auf das links abgebildete Ladegerät schalten Sie den unteren grünen Balken zum Debuggen der Anwendung aus"])},
      "debug-title-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht berechtigt, die grüne Taskleiste für das links aufgeführte Ladegerät zu aktivieren, um das Debuggen zu beschleunigen"])}
    }
  },
  "account": {
    "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Zugriffskonto (Administrator) für das zentrale Dashboard aus, um es anzuzeigen oder Änderungen vorzunehmen"])},
    "choice-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nun können Änderungen am ausgewählten Zugangskonto vorgenommen werden"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein neues Konto (Administrator) für den Zugriff auf das zentrale Dashboard"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine kurze Benachrichtigungsbeschreibung für diesen Zugriff ein"])},
    "note-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine kurze Beschreibung dieses Ansatzes"])},
    "note-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Text ein, der den bearbeiteten Ansatz charakterisieren soll"])},
    "note-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 10, maximal 80 Zeichen"])},
    "name-concat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nähere Informationen zur Kontaktaufnahme mit dem Administrator"])},
    "name-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor- und Nachname des Administrators/Eigentümers"])},
    "type-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriffstyp „Administrator/Eigentümer“."])},
    "mobil-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefon kontaktieren"])},
    "mail-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt-E-Mailadresse"])},
    "name-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name und Nachname"])},
    "name-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor- und Nachname des Ansprechpartners, Eigentümers oder Administrators"])},
    "name-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 2 Wörter mit jeweils mindestens 2 Zeichen"])},
    "type-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Daten müssen eingegeben werden, da sie die Zugriffsrechte charakterisieren"])},
    "type-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es muss die Zugangsart eingegeben werden"])},
    "mobil-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "mobil-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakttelefonnummer (mobil) für den Administrator des Remote-Punkts"])},
    "mobil-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format +420 999 999999"])},
    "mail-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische Adresse"])},
    "mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische Adresse des Eigentümers oder Administrators – sogenannte E-Mail"])},
    "mail-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die korrekte Form der Adresse ist "])},
    "name-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greifen Sie mit dem aktuell geladenen Zugriff auf das Dashboard auf Daten und Verbindungen zu"])},
    "login-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "pass-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "pass-conf-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen"])},
    "trial-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
    "active-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver Ansatz"])},
    "delete-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff löschen"])},
    "login-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Eintrag wird der Name eingetragen, unter dem sich der Administrator am System anmeldet"])},
    "login-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])},
    "pass-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie nach dem Namen das Passwort ein"])},
    "pass-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wurde ein Login-Name eingegeben, muss in diesem Eintrag das Passwort für die Anmeldung am Dashboard eingetragen werden"])},
    "pass-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acht Zeichen (a-z, A-Z, 0-9) + ein weiteres Zeichen"])},
    "pass-conf-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen Sie nach dem Namen das Passwort"])},
    "pass-conf-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Login-Name eingegeben wurde, muss in diesem Eintrag das Passwort wiederholt werden, das mit dem links eingegebenen übereinstimmen muss"])},
    "pass-conf-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter müssen übereinstimmen"])},
    "trial-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird in diesem Eintrag ein Datum eingetragen, ist bzw. wird der Zugang nur bis zu diesem Datum gültig sein"])},
    "trial-warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporärer Zugang"])},
    "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der aktuell angezeigte Zugang kann gelöscht werden, die Löschung ist fiktiv und kann jederzeit abgebrochen werden"])},
    "active-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der aktuell angezeigte Zugang kann als aktiv oder inaktiv eingestellt werden"])},
    "active-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff ist aktiv"])},
    "active-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff ist nicht aktiv"])},
    "delete-not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff ist vorhanden"])},
    "delete-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff wird gelöscht"])},
    "owner-ship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das angezeigte Zugangskonto bezieht sich auf den Besitzer des Ladegeräts"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die aktuellen Daten für dieses Zugangskonto ein"])}
  },
  "languages": {
    "remote-point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Fernpunkt"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie die Sprache eines anderen Landes hinzu"])},
    "uses-all-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das aktuell eingestellte Ladegerät verwendet alle Sprachen, oder es liegt irgendwo ein Fehler vor"])},
    "no-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus diesem Grund ist derzeit keine Sprache verfügbar"])},
    "in-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Flags in der jeweiligen Remote-Point-Anwendung werden in der unten gezeigten Reihenfolge angezeigt"])},
    "in-line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sprache mit der unten angezeigten Landesflagge wird zuerst als lokale Sprache des entfernten Punkts betrachtet"])},
    "in-line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie die aktuelle Bestellung ändern möchten, bestätigen Sie zunächst die Markierung, deren Standort Sie ändern möchten"])},
    "in-line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und bestätigen Sie dann die Flagge, auf der die am Mauszeiger angezeigte Flagge platziert werden soll"])},
    "in-line-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Flaggen auch über die links und rechts angezeigten Pfeile austauschen"])},
    "move-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegen Sie die Flagge"])},
    "move-flag-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach links"])},
    "move-flag-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach rechts"])},
    "remove-flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brechen Sie die Verwendung dieser Sprache in der Remote-Point-Anwendung ab    "])}
  },
  "login": {
    "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Login-Name"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "your-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Login-Passwort"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "view-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Bestätigen dieses Symbols wird das eingegebene Passwort für drei Sekunden angezeigt"])},
    "login-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich verifiziere die eingegebenen Daten"])},
    "undef-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein nicht identifizierbarer Fehler aufgetreten"])},
    "login-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkorrekte Log-in Information"])},
    "enterTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreten"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeloggt"])},
    "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "out-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie diesen Teil mit der Maus bestätigen, melden Sie sich von dieser Anwendung ab"])},
    "type": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Benutzertyp"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufsicht"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besitzer des Ladegeräts"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektentwickler"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang für einen festgelegten Zeitraum"])}
    }
  },
  "top-menu": {
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein entfernter Punkt"])},
    "advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werbung"])},
    "langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
    "connects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschlüsse"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Wetter"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
    "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang"])}
  },
  "xxxxxxxxxxxxxxxxxxxxxxxxxxxxvideo": {
    "first-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ein Werbevideo ein, das auf Wunsch des Ladekunden abgespielt wird"])}
  },
  "sliders": {
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Dateien hochladen"])},
    "count-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der hochgeladenen Dateien"])},
    "ext-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Dateiendung ist nicht zulässig"])},
    "shot-move-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegen Sie den Rahmen um eins zurück"])},
    "shot-move-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegen Sie den Rahmen um eins nach vorne"])},
    "shot-allow-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie eine Aufnahme an einem entfernten Punkt an"])},
    "shot-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzeige dieses Bildes ist am entfernten Punkt deaktiviert"])},
    "shot-view-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Zeitlimit für die Anzeige dieses Bildes in Sekunden"])},
    "shot-save-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie das Zeitlimit für die Anzeige des Bildes ein - max. 99 Sekunden"])},
    "shot-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch doppeltes Bestätigen des Symbols wird das aktuelle Bild physisch gelöscht"])},
    "direct-param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Parameter ist obligatorisch"])},
    "view-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der Aufnahmen, die auf dem Ladegerät angezeigt wird"])},
    "move-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich vertausche die beiden Aufnahmen miteinander"])},
    "delete-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich lösche das Element, nachdem ich die Löschung zweimal bestätigt habe"])},
    "max-limit-shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maximale Anzeigegrenze beträgt 99 Sekunden"])},
    "limit-allow-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können das Limit der Bildanzeige in Sekunden eingeben"])},
    "save-limit-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich schreibe das festgelegte Limit für den bestätigten Schuss auf"])},
    "set-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der bestätigte Schuss wird als aktiv gesetzt"])},
    "set-no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein bestätigter Schuss wird als inaktiv gesetzt"])},
    "load-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich lade die ausgewählten Dateien in das oben eingestellte Ladegerät"])},
    "out-of-sizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die minimalen Bildabmessungen betragen 1000px / 320px"])}
  },
  "switch": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEIN"])}
  },
  "charger": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine andere Anwendung auf dem entfernten Punkt"])},
    "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein entfernter Punkt"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt zu"])}
  },
  "lang": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache hinzufügen"])}
  },
  "setFlags": {
    "line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Flags in der jeweiligen Remote-Point-Anwendung werden in der unten gezeigten Reihenfolge angezeigt. "])},
    "line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sprache mit der unten angezeigten Landesflagge wird zuerst als Landessprache der Gegenstelle betrachtet. "])},
    "line-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie die aktuelle Reihenfolge ändern möchten, bestätigen Sie zuerst das Flag, dessen Position Sie ändern müssen, "])},
    "line-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und bestätigen Sie dann die Flagge, auf die die am Mauszeiger angezeigte Flagge platziert werden soll."])},
    "not-avail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der aktuell eingestellte Remote-Punkt verwendet alle Sprachen. "])},
    "not-avail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daher ist in diesem Fenster derzeit keine andere Sprache verfügbar."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachalternative löschen"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegen Sie die Flagge"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtung links"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["richtige Richtung"])}
  },
  "chargers": {
    "connect-01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Remote-Punkt-Anwendung, die zuletzt mit dem Server verbunden war"])},
    "connect-02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Zeitpunkt"])},
    "save-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie aktuelle Informationen zu diesem Ladegerät ein"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installieren Sie die Thin-Anwendung an einem Remote-Punkt neu oder starten Sie sie einfach neu"])},
    "note": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine kurze Definition eines Remote-Point-Ladegeräts"])}
    },
    "owner": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Besitzer des Ladegeräts mit der Remote-Point-Anwendung"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Eigentümers"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Stadt"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikation"])}
    },
    "person": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner des Besitzers des Ladegeräts mit der Anwendung an der entfernten Stelle"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name und Nachname"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
    },
    "wifi": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren/deaktivieren Sie gemeinsam genutztes WI-FI auf dem entfernten Punkt "])},
      "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN aktivieren"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich bei WLAN an"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN Passwort"])},
      "title-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option zur Eingabe von Begleittext, der angezeigt werden kann, wenn WLAN aktiviert ist"])},
      "title-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option zur Eingabe von Begleittext, der angezeigt werden kann, wenn WLAN nicht aktiviert ist"])}
    },
    "other-sets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Einstellungen, Uhranzeige, Zeitintervalle in Sekunden, Schlafmodus und Datenaktualisierungsprüfung"])},
    "clock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitaluhr"])},
    "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehren Sie im Leerlauf zum Start der Anwendung zurück"])},
    "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen von Datenaktualisierungen auf dem Server"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Anwendung"])},
    "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Route zur Wanderkarte ein, idealerweise mit dem API-Schlüssel und den Koordinaten des Ladestandorts"])},
    "error": {
      "note": {
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Grundbezeichnung des Ladegeräts muss mindestens 5 Zeichen lang sein"])}
      },
      "restart": {
        "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anwendung auf dem aktuellen Remote-Punkt wird neu gestartet, wenn sie sich das nächste Mal mit der Datenbank verbindet"])},
        "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Neuinstallation auf der Gegenstellenseite wurde verhindert"])}
      },
      "owner": {
        "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])},
        "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Nummern"])},
        "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])},
        "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 3 Zeichen"])},
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Beispiel +421 999 999999"])},
        "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meno.priezvisko--at--domena.de"])}
      },
      "wifi": {
        "allow-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN ist am Ladegerät aktiviert"])},
        "allow-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN ist am Ladegerät nicht aktiviert"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])},
        "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 5 Zeichen"])}
      },
      "digital-clock-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheimische Uhr"])},
      "digital-clock-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitaluhr"])},
      "globalInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestwert ist 3 (Minuten)"])},
      "controlInterval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestwert ist 3 (Minuten)"])},
      "active-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische Pause"])},
      "active-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Anwendung"])},
      "map-path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Pfad zur Touristenkarte muss eine URL-Adresse sein (http://, https://)"])}
    }
  },
  "select": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Selektor ist derzeit ohne Angebot"])}
  },
  "access": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen neuen Administrator"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie den bearbeiteten Administrator"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine kurze Beschreibung dieses Ansatzes"])},
    "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Administrators / Eigentümers"])},
    "char-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkmale von Berechtigungen"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy-Kontaktnummer"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt-E-Mailadresse"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren Anmeldenamen ein"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihr Login-Passwort ein"])},
    "pass-icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passworteingabe anzeigen"])},
    "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie das Passwort erneut ein"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Zugriff ist aktiv"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff wird als gelöscht markiert"])},
    "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testzugang"])},
    "owner-necessary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie einen bestehenden Ladegerätebesitzer aus oder tragen Sie einen neuen ein"])},
    "owner-chargers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie zusätzlich zu Supervisor, Entwickler und Tester bestimmte Ladegeräte für andere Zugriffsarten zur Verfügung"])},
    "owner-chargers-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie das Ladegerät aus, dem der Eigentümer des Geräts zugewiesen werden soll"])},
    "error": {
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Grundbezeichnung des Zugangskontos muss mindestens 5 Zeichen lang sein"])},
      "name-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor- und Nachname mindestens 5 Zeichen + Leerzeichen  "])},
      "char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugangsmerkmale müssen eingegeben werden"])},
      "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideales Zahlenformat"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Form der E-Mail-Adresse"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie mindestens 5 gültige Zeichen ein"])},
      "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Großbuchstaben, Kleinbuchstaben, Zahlen und andere Zeichen"])},
      "pass-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es stimmt nicht mit dem links eingegebenen Passwort überein"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff ist derzeit aktiv"])},
      "not-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugang ist vorübergehend gesperrt"])},
      "not-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff wird nicht als gelöscht markiert"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff wurde als gelöscht markiert"])},
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das angeforderte Format ist"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie ist bis zum angegebenen Datum gültig"])}
    }
  },
  "owner": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff wird dem Eigentümer zugewiesen"])},
    "exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können einen bestehenden Eigentümer auswählen"])},
    "new-alter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oder erstellen Sie einen neuen Besitzer"])},
    "create-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen neuen Eigentümer"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Ladegerätbesitzers"])},
    "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Eigentümeridentifikation"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse, Straßenname, Inventar und Seriennummer"])},
    "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitz, Dorf, Stadt - sein Teil"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie Änderungen an die Eigentümer des Ladegeräts"])},
    "error": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie mindestens 5 gültige Zeichen ein"])},
      "ico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens drei gültige Zeichen"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format 99999 oder 999 99"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Beispiel Straßenname 423/1c"])},
      "seat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens zwei Zeichen, Name der Gemeinde - ihr Teil"])}
    },
    "ship": {
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelklicken Sie mit der Maus, um den Besitz dieses Ladegeräts aufzuhebenAz egérrel történő dupla megerősítés kikapcsolja az aktivált hibakeresőt - az alsó részben lévő zöld sáv nem jelenik meg"])},
      "debug-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch doppeltes Bestätigen mit der Maus schalten Sie den Debugger ein – den grünen Balken im unteren Teil"])},
      "debug-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch eine doppelte Bestätigung mit der Maus wird der aktivierte Debugger ausgeschaltet – der grüne Balken im unteren Teil wird nicht angezeigt"])}
    }
  },
  "weather": {
    "use-system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendetes meteorologisches System"])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Längengrad"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])},
    "sea-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe über dem Meeresspiegel"])},
    "save-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie Informationen zur Wettervorhersage"])},
    "azimut-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windrichtung (Azimut)"])},
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Wettervorhersage ist derzeit nicht verfügbar!"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klar"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halb klar"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wolkig"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fast bewölkt"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["völlig bewölkt"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stündliche Wettervorhersage"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windrichtung"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Regen"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windgeschwindigkeit"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windstöße"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftdruck"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es schneit"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sonnenaufgang"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sonnenuntergang"])}
  },
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])}
  },
  "cms": {
    "deposit": {
      "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeiten Sie mit CMS-Inhalten und deren Hinterlegung"])},
      "head-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können den aktuellen Inhalt dieses CMS im Depot speichern, ihn aus einem anderen Depot auswählen oder den von Ihnen im Depot erstellten Inhalt löschen"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie eine Einzahlung aus diesem CMS-Inhalt"])},
      "create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie diesen Teil bestätigen, können Sie den aktuellen Inhalt des CMS hinterlegen"])},
      "insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie eine Einzahlung in diesen CMS-Inhalt"])},
      "insert-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Inhalte aus den verfügbaren Repositories in dieses CMS laden"])},
      "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können eine Vorlage aus den im Repository verfügbaren Vorlagen auswählen und deren Inhalt in dieses CMS laden"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen Sie die Hinterlegung dieses Inhalts"])},
      "delete-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können das erstellte Depot für das aktuelle CMS jederzeit löschen, es ist jedoch praktischer, den Inhalt im Depot zu behalten"])},
      "not-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es erfolgt keine Hinterlegung dieser Inhalte"])},
      "not-exist-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte die Hinterlegung für das aktuelle CMS nicht vorhanden sein, empfiehlt es sich, diese aufzubewahren und auf einem aktuellen Stand zu halten"])},
      "insert-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie den Inhalt dieses CMS wirklich in einem Depot hinterlegen?"])},
      "insert-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie den Inhalt des CMS in die Hinterlegung"])},
      "insert-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht"])},
      "read-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie die ausgewählte Einzahlung in dieses CMS"])},
      "read-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht"])},
      "delete-quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Inhalt der Einzahlung bezüglich dieses CMS löschen ?"])},
      "delete-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machen Sie die Anzahlung frei"])},
      "delete-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf dem Bildschirm anzeigen"])},
      "preview-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche wird der Inhalt des aktuellen CMS in den Abmessungen des eingestellten Bildschirms angezeigt"])},
      "remove-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen Sie alle CMS-Inhalte"])},
      "remove-all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überlegen Sie sich genau, ob Sie durch doppeltes Bestätigen dieser Schaltfläche alle aktuellen Inhalte des CMS löschen möchten"])}
    },
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider bereiten wir den Inhalt noch vor."])},
    "choice-flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus dieser Auswahl an Ländern kann die Sprache ausgewählt werden, in der die Inhalte aktuell innerhalb des gesamten CMS-Tools verwaltet werden sollen."])},
    "line-move-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschiebt die aktuelle Zeile im Inhalt um eine Position nach oben."])},
    "line-move-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschieben Sie im Inhaltsverzeichnis die aktuelle Zeile um eins nach unten."])},
    "line-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie die aktuelle Zeile in der Anwendung an."])},
    "line-not-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die aktuelle Zeile in der Anwendung nicht anzeigen."])},
    "line-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie die Einstellungen und Parameter der aktuellen Linie."])},
    "line-add-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelklicken Sie auf die Position dieser Zeile, um eine weitere Zeile hinzuzufügen, diese Zeile wird unten angezeigt."])},
    "copy-style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren Sie die Linie mit allem Zubehör. Unter Zubehör verstehen wir die Stileinstellungen der Linie, Bilder, die zur kopierten Linie gehören, falls sie hochgeladen wurden."])},
    "line-trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte Bestätigung, um die aktuelle Zeile mit der Möglichkeit der Wiederherstellung in den Papierkorb zu werfen."])},
    "line-recover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöschte Zeile wiederherstellen."])},
    "win-line-modal": {
      "close-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behalten Sie die vorgenommenen Änderungen in der Zeile bei und schließen Sie das Fenster."])},
      "close-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie die Maus, um das Fenster mit den in den Zeileneinstellungen vorgenommenen Änderungen zu schließen."])},
      "close-no-save-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelklicken Sie mit der Maus, um das Fenster zu schließen, ohne die Änderungen in den Zeileneinstellungen zu speichern. Die ursprüngliche Einstellung wird in der bearbeiteten Zeile wiederhergestellt."])}
    },
    "image": {
      "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen für Bilder."])},
      "img-width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im linken Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter dessen Breite angeben. Es muss so vorgegangen werden, dass das Bild nicht verzerrt bleibt."])},
      "img-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im linken Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter dessen Höhe angeben. Es muss so vorgegangen werden, dass das Bild nicht verzerrt bleibt."])},
      "img-margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im linken Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter dessen Versatz von der Obergrenze der Zeile einstellen."])},
      "img-margin-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn sich im linken Teil der CMS-Zeile ein Bild befindet, können Sie mit diesem Parameter den Abstand vom rechten Rand der Zeile festlegen."])},
      "img-margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn sich im linken Teil der CMS-Zeile ein Bild befindet, können Sie mit diesem Parameter den Abstand zum unteren Rand der Zeile festlegen."])},
      "img-margin-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im linken Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter den Abstand zum linken Rand der Zeile festlegen."])},
      "img-width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im linken Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter dessen Breite angeben. Es muss so vorgegangen werden, dass das Bild nicht verzerrt bleibt."])},
      "img-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im linken Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter dessen Höhe angeben. Es muss so vorgegangen werden, dass das Bild nicht verzerrt bleibt."])},
      "img-margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im rechten Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter dessen Abstand zur oberen Begrenzung der Zeile einstellen."])},
      "img-margin-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im rechten Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter den Abstand zum rechten Rand der Zeile festlegen."])},
      "img-margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im rechten Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter dessen Abstand zur unteren Begrenzung der Zeile einstellen."])},
      "img-margin-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befindet sich im rechten Teil der CMS-Zeile ein Bild, können Sie mit diesem Parameter den Abstand zum linken Rand der Zeile festlegen."])},
      "image-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit diesem Tool können Sie ein neues Bild in den linken Teil hochladen. Das vorhandene Bild auf der linken Seite wird ersetzt. Wenn der linke Teil der CMS-Zeile Text enthält, bleibt dieser erhalten und erscheint wieder, wenn das Bild gelöscht wird."])},
      "image-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit diesem Tool können Sie ein neues Bild in den rechten Bereich hochladen. Das vorhandene Bild auf der rechten Seite wird ersetzt. Wenn der rechte Teil der CMS-Zeile Text enthält, bleibt dieser erhalten und erscheint wieder, wenn das Bild gelöscht wird."])},
      "upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie das Bild im linken Teil der Zeile ein"])},
      "upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie das Bild im rechten Teil der Zeile ein"])},
      "upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im linken Teil der CMS-Zeile kann neben dem Text auch ein Bild eingefügt werden, das Sie vom lokalen Rechner auswählen. Es ist möglich, die Breite, Höhe und den Abstand von den Liniengrenzen für das Bild festzulegen."])},
      "remove-upload-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen Sie das Bild aus dem linken Teil der Zeile"])},
      "remove-upload-r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen Sie das Bild aus dem rechten Teil der Zeile"])},
      "remove-upload-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn sich im linken Teil der Zeile ein Bild befindet, können Sie es mit einem Doppelklick mit der Maus löschen. Wenn Sie in der Vergangenheit einen Text im linken Teil eingegeben haben, bleibt dieser erhalten und wird in diesem Teil erneut angezeigt."])},
      "remove-upload-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn sich im rechten Teil der Zeile ein Bild befindet, können Sie es mit einem Doppelklick mit der Maus löschen. Wenn Sie in der Vergangenheit einen Text im rechten Teil eingegeben haben, bleibt dieser erhalten und wird in diesem Teil erneut angezeigt."])}
    },
    "edit-line": {
      "change-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie den Text"])},
      "change-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Bestätigen dieser Schaltfläche sind Eingriffe und Änderungen am Text im linken Teil der CMS-Zeile möglich."])},
      "save-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben"])},
      "change-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Bestätigen dieser Schaltfläche sind Eingriffe und Änderungen am Text im rechten Teil der CMS-Zeile möglich."])},
      "copy-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Verwendung von CMS (Content Management) zur Arbeitserleichterung gibt es im linken Teil der Zeile die Möglichkeit, bereits vorbereitete Texte aus einer anderen Sprache in die gleiche Zeile und in den gleichen Teil davon – also aus einer anderen Sprachversion – zu kopieren der aktuellen CMS-Inhalte."])},
      "copy-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Verwendung von CMS (Content Management) zur Arbeitserleichterung besteht im rechten Teil der Zeile die Möglichkeit, bereits vorbereitete Texte aus einer anderen Sprache in die gleiche Zeile und in den gleichen Teil davon – also aus einer anderen Sprachversion – zu kopieren der aktuellen CMS-Inhalte."])},
      "save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche werden alle Änderungen gespeichert und der Textbearbeitungsmodus beendet. Wenn Sie auf das Mülleimer-Symbol ganz oben links drücken, werden die geschriebenen Änderungen einschließlich des Textinhalts zurückgesetzt."])},
      "no-save-text-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken des Symbols mit dem Mülleimer wird der Textbearbeitungsmodus verlassen, ohne die Änderungen zu speichern."])},
      "no-save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken des Symbols mit dem Mülleimer wird der Textbearbeitungsmodus verlassen, ohne die Änderungen zu speichern."])},
      "save-text-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken dieser Schaltfläche werden alle Änderungen gespeichert und der Textbearbeitungsmodus beendet. Wenn Sie auf das Mülleimer-Symbol ganz oben links drücken, werden die geschriebenen Änderungen einschließlich des Textinhalts zurückgesetzt."])},
      "change-l-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertausche den linken und den rechten Teil der Linie."])},
      "width-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie die Breite des linken Teils der Linie fest, mindestens 1 % / max. 99%."])},
      "width-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie die Breite des rechten Teils der Linie fest, mindestens 1 % / max. 99%."])},
      "no-view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Bestätigen des Symbols, das so eingestellt ist, dass der linke Teil der Zeile nicht angezeigt wird, belegt der rechte Teil die gesamte Zeile."])},
      "view-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Bestätigen des Symbols, um den linken Teil der Linie anzuzeigen, wird die Breite beider Teile der Linie auf 50 % initialisiert."])},
      "no-view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie das einzustellende Symbol, damit der rechte Teil der Zeile nicht angezeigt wird und der linke Teil die gesamte Zeile einnimmt."])},
      "view-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Bestätigen des eingestellten Symbols, sodass der rechte Teil der Linie angezeigt wird, wird die Breite beider Teile der Linie auf 50 % initialisiert."])},
      "disabled-view-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nicht möglich, die Anzeige aller seiner Teile in einer bestimmten Reihe auszuschalten."])},
      "height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Bedarf können Sie auch die Höhe des linken Teils der Zeile einstellen. Die Höhe darf 50px nicht unterschreiten und darf auch die Gesamthöhe der Zeile nicht überschreiten."])},
      "height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Bedarf können Sie auch die Höhe des rechten Teils der Zeile anpassen. Die Höhe darf 50px nicht unterschreiten und darf auch die Gesamthöhe der Zeile nicht überschreiten."])},
      "font-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über diesen Selektor ist es möglich, eine andere Schriftart einzustellen, die im linken Teil der Zeile verwendet werden soll. Wenn Sie eine komplett neue Schriftart hinzufügen müssen, wenden Sie sich mit der Anfrage bitte an den obersten Administrator."])},
      "font-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über diesen Selektor ist es möglich, eine andere Schriftart einzustellen, die im rechten Teil der Zeile verwendet werden soll. Wenn Sie eine komplett neue Schriftart hinzufügen müssen, wenden Sie sich mit der Anfrage bitte an den obersten Administrator."])},
      "color-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der Maus haben Sie die Möglichkeit, die Farbe des Textes im linken Teil der Zeile zu ändern. Der Wert muss immer in hexadezimaler Form (Hexadezimal) mit drei oder sechs Zeichen (1 bis 9, A bis F) vorliegen, sonst ist er ungültig."])},
      "color-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der Maus haben Sie die Möglichkeit, die Farbe des Textes im rechten Teil der Zeile zu ändern. Der Wert muss immer in hexadezimaler Form (Hexadezimal) mit drei oder sechs Zeichen (1 bis 9, A bis F) vorliegen, sonst ist er ungültig."])},
      "font-size-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert gibt die Möglichkeit, die Schriftgröße im linken Teil der Zeile zu ändern. Von den vielen Optionen verwendet dieses CMS die em-Einheit, da sie bei einer responsiven Anzeige relativ sinnvoll ist. Erlaubt 0,5 bis 4,9 (em)."])},
      "font-size-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert gibt die Möglichkeit, die Schriftgröße im rechten Teil der Zeile zu ändern. Von den vielen Optionen verwendet dieses CMS die em-Einheit, da sie bei einer responsiven Anzeige relativ sinnvoll ist. Erlaubt 0,5 bis 4,9 (em)."])},
      "font-weight-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im linken Teil der bearbeiteten Zeile kann die Dicke der verwendeten Schriftart eingestellt werden. Die Anwendungen sind so entwickelt, dass die Dicke der Schrift möglichst feinfühlig eingestellt werden kann."])},
      "font-weight-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im rechten Teil der bearbeiteten Zeile kann die Dicke der verwendeten Schriftart eingestellt werden. Die Anwendungen sind so entwickelt, dass die Dicke der Schrift möglichst feinfühlig eingestellt werden kann."])},
      "font-style-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was den Schriftstil betrifft, so können Sie im linken Teil der Zeile eine normale Schriftart oder Kursivschrift einstellen. Andere Optionen wurden aufgrund schlechter Kompatibilität zwischen Schriftarten ignoriert."])},
      "font-style-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was den Schriftstil betrifft, so können Sie im rechten Teil der Zeile eine normale Schriftart oder Kursivschrift einstellen. Andere Optionen wurden aufgrund schlechter Kompatibilität zwischen Schriftarten ignoriert."])},
      "background-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Inhaltsverwaltung ermöglicht es Ihnen, die Hintergrundfarbe auch nur im linken Teil der bearbeiteten Zeile zu ändern. Der Wert muss immer in hexadezimaler Form (Hexadezimal) mit drei oder sechs Zeichen (1 bis 9, A bis F) vorliegen, sonst ist er ungültig. Die Farbe für nur einen Teil der Linie ist nicht unbedingt angegeben."])},
      "background-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Inhaltsverwaltung ermöglicht es Ihnen, die Hintergrundfarbe auch nur im rechten Teil der bearbeiteten Zeile zu ändern. Der Wert muss immer in hexadezimaler Form (Hexadezimal) mit drei oder sechs Zeichen (1 bis 9, A bis F) vorliegen, sonst ist er ungültig. Die Farbe für nur einen Teil der Linie ist nicht unbedingt angegeben."])},
      "line-height-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Bearbeitung des linken Teils der Zeile kann auch die Höhe des Textversatzes zwischen den Textzeilen eingestellt werden. Dadurch werden einzelne Textzeilen vertikal versetzt. Dieser Wert muss nicht eingegeben werden."])},
      "line-height-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Bearbeitung des rechten Teils der Zeile kann auch die Höhe des Texteinzugs zwischen den Textzeilen eingestellt werden. Dadurch werden einzelne Textzeilen vertikal versetzt. Dieser Wert muss nicht eingegeben werden."])},
      "text-align-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im linken Teil der Zeile haben wir die Möglichkeit, den horizontalen Versatz des Textes einzustellen. left - bedeutet den Text linksbündig auszurichten, right - bedeutet den Text rechtsbündig auszurichten, center - bedeutet den Text zentriert auszurichten und Blocksatz - bedeutet den Text gleichmäßig an der gesamten Breite des Elements auszurichten."])},
      "text-align-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im rechten Teil der Zeile haben wir die Möglichkeit, den horizontalen Versatz des Textes einzustellen. left - bedeutet den Text linksbündig auszurichten, right - bedeutet den Text rechtsbündig auszurichten, center - bedeutet den Text zentriert auszurichten und Blocksatz - bedeutet den Text gleichmäßig an der gesamten Breite des Elements auszurichten."])},
      "text-decor-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Bonus können Sie den Text im linken Teil auch mit der Unterstreichungsoption unterstreichen. Wenn Sie none angeben, wird der Text nicht unterstrichen."])},
      "text-decor-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Bonus können Sie den Text im rechten Teil auch unterstreichen, indem Sie Unterstreichen auswählen. Wenn Sie none angeben, wird der Text nicht unterstrichen.Als Bonus können Sie den Text im linken Teil auch mit der Unterstreichungsoption unterstreichen. Wenn Sie none angeben, wird der Text nicht unterstrichen."])},
      "padding-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Werte neben diesem Symbol dienen dazu, den Text innerhalb des linken Teils der bearbeiteten Zeile einzurücken. Weitere Informationen werden angezeigt, wenn Sie den Mauszeiger auf einen bestimmten Wert in unmittelbarer Nähe des Symbols setzen."])},
      "margin-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Werte neben diesem Symbol dienen dazu, den linken Teil der bearbeiteten Zeile nach oben oder unten zu versetzen. Es ist nicht möglich, den inneren Teil der Zeile von links oder rechts einzurücken. Weitere Informationen werden angezeigt, wenn Sie den Mauszeiger auf einen bestimmten Wert in unmittelbarer Nähe des Symbols setzen."])},
      "margin-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Werte neben diesem Symbol dienen dazu, den rechten Teil der bearbeiteten Zeile von oben oder unten zu versetzen. Es ist nicht möglich, den inneren Teil der Zeile von links oder rechts einzurücken. Weitere Informationen werden angezeigt, wenn Sie den Mauszeiger auf einen bestimmten Wert in unmittelbarer Nähe des Symbols setzen."])},
      "padding-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Werte neben diesem Symbol werden verwendet, um den Text innerhalb des rechten Teils der bearbeiteten Zeile zu versetzen. Weitere Informationen werden angezeigt, wenn Sie den Mauszeiger auf einen bestimmten Wert in unmittelbarer Nähe des Symbols setzen."])},
      "padding-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der Text im linken Teil von seinem oberen Rand versetzt werden soll. Wenn der Wert leer ist, wird kein oberer Offset gesetzt."])},
      "margin-top-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der linke Teil der Linie vom oberen Rand der Linie versetzt sein soll. Wenn der Wert leer ist, wird kein oberer Offset gesetzt."])},
      "margin-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der rechte Teil der Linie vom oberen Rand der Linie versetzt sein soll. Wenn der Wert leer ist, wird kein oberer Offset gesetzt."])},
      "margin-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der linke Teil der Linie vom unteren Rand der Linie versetzt sein soll. Wenn der Wert leer ist, wird kein Padding gesetzt."])},
      "margin-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der rechte Teil der Linie vom unteren Rand der Linie versetzt sein soll. Wenn der Wert leer ist, wird kein Padding gesetzt."])},
      "padding-right-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der Text im linken Teil von seinem rechten Rand versetzt werden soll. Wenn der Wert leer ist, wird kein rechter Offset gesetzt."])},
      "padding-bottom-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der Text im linken Teil von seinem unteren Rand versetzt werden soll. Wenn der Wert leer ist, wird kein Versatz von der Unterseite gesetzt."])},
      "padding-left-l-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der Text im linken Teil von seinem linken Rand versetzt werden soll. Wenn der Wert leer ist, wird kein linker Offset gesetzt."])},
      "padding-top-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der Text im rechten Teil von seinem oberen Rand versetzt werden soll. Wenn der Wert leer ist, wird kein oberer Offset gesetzt."])},
      "padding-right-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der Text im rechten Teil von seinem rechten Rand versetzt werden soll. Wenn der Wert leer ist, wird kein rechter Offset gesetzt."])},
      "padding-bottom-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der Text im rechten Teil von seinem unteren Rand versetzt werden soll. Wenn der Wert leer ist, wird kein Versatz von der Unterseite gesetzt."])},
      "padding-left-r-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Wert bestimmt, um wie viele Pixel (Punkte) der Text im rechten Teil von seinem linken Rand versetzt werden soll. Wenn der Wert leer ist, wird kein linker Offset gesetzt."])},
      "height-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie die Höhe der gesamten bearbeiteten Zeile fest. Sie sollte nicht kleiner als 50 Pixel (Dots) und größer als 1500 Pixel (Dots) sein."])},
      "background-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit diesem Wert kann die Hintergrundfarbe der gesamten Zeile eingestellt werden. Der Wert muss immer in hexadezimaler Form (Hexadezimal) mit drei oder sechs Zeichen (1 bis 9, A bis F) vorliegen, sonst ist er ungültig."])},
      "margin-glob-icon-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem Wert rechts neben dem Symbol kann der Abstand der inneren Container der Reihe vom oberen Rand der Reihe eingestellt werden. In diesem Zusammenhang empfehlen wir, auch die Einstellung der Gesamthöhe der Reihe zu verwenden, mit der auch der untere Teil der Reihe versetzt werden kann."])},
      "margin-glob-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit diesem Wert kann der Abstand der inneren Container der Reihe vom oberen Rand der Reihe eingestellt werden. Wir empfehlen in diesem Zusammenhang auch die Einstellung der Gesamthöhe der Linie zu verwenden, wodurch ein Versatz auch vom unteren Ende der Linie erreicht werden kann."])}
    },
    "view-delete": {
      "text-date-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile rechtzeitig gelöscht"])},
      "active-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur aktive Zeilen anzeigen"])},
      "active-only-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Bestätigen dieser Schaltfläche werden auch als gelöscht markierte CMS-Zeilen angezeigt."])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch gelöschte Zeilen anzeigen"])},
      "all-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Bestätigen dieser Schaltfläche werden auch als gelöscht markierte CMS-Zeilen angezeigt."])},
      "drop-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch doppelte Bestätigung wird die logisch gelöschte CMS-Zeile endgültig gelöscht, diese Löschung kann nicht mehr rückgängig gemacht werden."])},
      "restore-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch eine doppelte Bestätigung wird die logisch gelöschte CMS-Zeile wieder unter den Zeilen angezeigt, die nicht als gelöscht markiert wurden."])}
    }
  }
}