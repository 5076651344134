
import axios from 'axios'
import OBJ from "@/assets/modules/OBJMethodsClass.js"
import SPEC from "@/assets/modules/Specials"
export default class dbProcedures {

constructor(App) {

    this.App    = App

    this.OBJ    = new OBJ()
    this.SPEC   = new SPEC(this.App)
    this.script =   'assets/Modules/dbProc/'

}

//Call from PasswordGate.vue
startDbMirrioring   =   (scriptName)   =>   {

    let axios_ =    scriptName +  ' :: '  + this.script+'startDbMirrioring()'
    let formData = this.SPEC.formData(axios_)
    console.log('START MIRRIOR :: ' +axios_)

    formData.append('command',  'proc-startDbMirrioring')    
    //console.log('START :: startDbMirrioring')
    this.callProcessViaAxios(axios_, formData)
}

//Call from PasswordGate.vue
startServices   =   (scriptName, userId)    =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'startServices()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',      'proc-startServices')    
    let fields  =   '{'
    fields      +=   '"userId":"'+userId+'", '
    fields      +=   '"tablePrefix":"'+localStorage.projectName+'"}'
    formData.append('fields', fields)    

    this.callProcessViaAxios(axios_, formData)
}

//Call from PasswordGate.vue
attemptLogin  =   (scriptName, appVariable, login='', password='')   =>  {

    if(login.trim()===0)        return null
    if(password.trim()===0)     return null

    let axios_ =    scriptName +  ' :: '  + this.script+'attemptLogin()'

    let formData = this.SPEC.formData(axios_)   //  , login, password

    formData.append('command',  'proc-attemptLogin')    
    let fields = '{"login":"'+login+'", "password":"'+password+'"}'
    formData.append('fields',     fields)

    this.callProcessViaAxios(axios_, formData, appVariable)
}

//Global method (views/RemotePoint/_MainRemotePoint.js)
refreshTables   =   (oAppTablesObject) =>   {

    console.log(oAppTablesObject)
}

//Call from App.vue
getAccessTypes  =   (scriptName, appVariable)  =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'getAccessTypes()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',  'proc-getAccessTypes')    
    this.callProcessViaAxios(axios_, formData, appVariable)
}

//Call from App.vue, RefreshAddComponent.vue
getChargers =   (scriptName, appVariable, chargerId=0)   =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'getChargers()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',  'proc-getChargers')    
    let fields = '{"chargerId":"'+chargerId+'"}'
    formData.append('fields',  fields)    

    this.callProcessViaAxios(axios_, formData, appVariable)
}

/*
getAvailableCountries   =   (scriptName, appVariable, chargerId=0)   =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'getAvailableCountries()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',  'proc-getAvailableCountries')    
    let fields = '{"chargerId":"'+chargerId+'"}'
    formData.append('fields',  fields)    

    this.callProcessViaAxios(axios_, formData, appVariable)

}
*/



//Call from App.vue, PasswordGate.vue
getCurrentIP  =   (scriptName, appVariable)  =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'getCurrentIP()'
    let formData = this.SPEC.formData(axios_)

    //formData.append('command',  'proc-getSystemSetIP')    
    formData.append('command',  'proc-getCurrentIP')    
    
    this.callProcessViaAxios(axios_, formData, appVariable)
}

//Call from App.vue
getOwners   =   (scriptName, appVariable)  =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'getOwners()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',  'proc-getOwners')    
    this.callProcessViaAxios(axios_, formData, appVariable)
}

//Call from App.vue
getPromoVideos   =   (scriptName, appVariable)  =>  {  

    let axios_ =    scriptName +  ' :: '  + this.script+'getPromoVideos()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',  'proc-getPromoVideos')    
    this.callProcessViaAxios(axios_, formData, appVariable)
}

//Call from App.vue
getOwnerShipOnly    =   (scriptName, appVariable)  =>  {

    let axios_ = this.path + scriptName + ' :: ' + 'getOwnerShipOnly()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',  'proc-getOwnerShipOnly')    
    this.callProcessViaAxios(axios_, formData, appVariable)
}

//Call from App.vue, PasswordGate.vue
usersDashboard  =   (scriptName, appVariable, exceptString='')  =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'usersDashboard()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',      'proc-usersDashboard')    
    formData.append('exceptString',  exceptString)    
    this.callProcessViaAxios(axios_, formData, appVariable)
}

//Call from App.vue
flagForEachCharger  =   (scriptName)  =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'flagForEachCharger()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command', 'proc-flagForEachCharger')    

    //console.log(localStorage.URLRestApi+'/?&from-app=is&net-heavenly-gate=dashboard&net=local&command=proc-flagForEachCharger')
    this.callProcessViaAxios(axios_, formData)
}

callProcessViaAxios = (axios_='', formData, AppVariable='') =>  {

    //console.log(axios_)
    //console.log(localStorage.URLRestApi)
    axios.post(localStorage.URLRestApi, formData).then(response => {

        //if(axios_.indexOf('getSetBoardObject')>(-1))
            console.log(response.data)
        
        if(!this.SPEC.writeToLogIfError(response.data, formData, axios_))   {

            //console.log(this.App[AppVariable])
            if(AppVariable.trim().length>0)    
                this.App[AppVariable] = response.data       
            
            //console.log(AppVariable)
            //console.log(this.App[AppVariable])
        }
    }).catch(function(error) {

        console.error(error)
    })
}


/*  to do remove
//-------------------------------------------------------------------------------
callProcViaAxios = (axios_='', formData, viewResponse, appVariable='') =>  {

    let oJSON
    //console.log(axios_)
    //console.log(localStorage.URLRestApi)
    axios.post(localStorage.URLRestApi, formData).then(response => {
        
        //console.log(response.data)4
        if(!this.SPEC.writeToLogIfError(response.data, formData, axios_))   {
            if(viewResponse)    {

                //console.log(axios_)
                //console.log(response.data)
                
                / *
                if(appVariable.trim().length>0)    
                    console.log(appVariable)
                console.log('dashboard')
                console.log(localStorage.projectName)
                * /
                //let oUserLogin  =   this.tmpl.$store.state.oCurrentUser
                //if(this.OBJ.count(oUserLogin)>0)
                    //console.log(oUserLogin.login)
                //console.log('=====================')
            }
    
            if(appVariable.trim().length>0)    
                this.tmpl[appVariable] = response.data            
        }

    }).catch(function(error) {
        let _JSON = ''
        _JSON += '{'
        _JSON +=  '"error":"'+error.message+'",'
        _JSON +=  '"method":"' + axios_ + '",'
        if(appVariable.trim().length>0)    
            _JSON +=  '"templateVar":"' + appVariable + '",'
        _JSON +=  '"UID":"' + localStorage.netHeavenlyGate + '",'
        _JSON +=  '"project":"' + localStorage.projectName + '"}'
        oJSON = JSON.parse(_JSON)        

        console.log('=== AXIOS error: ===')
        console.log(oJSON.error)
        console.log(oJSON.method)

        if(typeof oJSON.templateVar!=='undefined')    
            if(oJSON.templateVar.trim().length>0)    
                console.log(oJSON.templateVar)

        console.log(oJSON.UID)
        console.log(oJSON.project)                
    })           
}
*/

/*  to do remove
setLimitForShot  =   (scriptName, shotId, limitVal ,chargerId)  =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'setLimitForShot()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command', 'proc-setLimitForShot')    
    formData.append('remoteId', chargerId)    
    formData.append('fields',   '{"id":"'+shotId+'", "limit":"'+limitVal+'", "charger":"'+chargerId+'"}')    

    this.callProcessViaAxios(axios_, formData)
}
­*/

/*  to do remove
removePrologVideo   =   (scriptName, appVariable, fields)  =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'removePrologVideo()'
    let formData = this.SPEC.formData(axios_)

    formData.append('command',  'proc-removePrologVideo')    
    formData.append('fields',  fields)    
    this.callProcessViaAxios(axios_, formData, appVariable)
}
*/

/*  to do remove
saveOwnerToAccess   =   (scriptName, appVariable, fields)  =>  {

    let axios_ =    scriptName +  ' :: '  + this.script+'saveOwnerToAccess()'
    let formData = this.SPEC.formData(axios_)    

    formData.append('command',  'proc-saveOwnerToAccess')    
    formData.append('fields',  fields)    
    this.callProcessViaAxios(axios_, formData, appVariable)
}
*/


}