
<template>
    <router-view  :key="rerender" />
</template>

<script>
    import      setApp          from    "@/App.json"

    import      OBJ             from    '@/assets/modules/OBJMethodsClass'
    import      PROC            from    "@/assets/modules/dbProc"

    export default {
        name: "App",
        data() {
            return {
                PROC:                       new PROC(this),
                OBJ:                        new OBJ(this),

                oCurrentIP:                 [],

                rerender:                   1,
                //oChargers:                  [],
                //oAllChargers:               [],
                
                //oOwners:                    [],
                //oPromoVideos:               [],
                //oOwnerShip:                 [],
                //oUsersDashboard:            [],

                //oAccessTypes:               [],
            }
        },
        watch:  {
            /*
            oAllChargers()  {
                this.$store.commit('oAllChargersToVuex', this.oAllChargers)
            
            },
            */
            /*
            oChargers() {
                if(this.OBJ.count(this.oChargers)>0)    {
                    this.$store.commit('oChargersToVuex',           this.oChargers)
                    this.$store.commit('oCurrentChargerToVuex',     this.oChargers[0])
                    this.$store.commit('currentChargerIdToVuex',    this.oChargers[0]['chg_id'])
                }
            },
            */
           /*
            oUsersDashboard()   {
                this.$store.commit('oUsersDashboardToVuex', this.oUsersDashboard)
            },
            */
            /*
            oOwners()   {
                this.$store.commit('oOwnersToVuex', this.oOwners)
            },
            */
           /*
            oPromoVideos()  {
                this.$store.commit('oPromoVideosToVuex', this.oPromoVideos)
            },
            */
           /*
            oOwnerShip()   {
                this.$store.commit('oOwnerShipToVuex', this.oOwnerShip)
            },            
            */
            /*
            oAccessTypes()  {
                this.$store.commit('oAccessTypesToVuex', this.oAccessTypes)
            },
            */  
            oCurrentIP()    {
                //console.log(this.oCurrentIP)
                localStorage.userType = 'user'
                if(typeof this.oCurrentIP.oAdminsIP === 'object')
                    for(let record of this.oCurrentIP.oAdminsIP)  {
                        if(record['admin-IP'].trim()===this.oCurrentIP['current-ip'].trim())
                        localStorage.userType = 'admin'
                    }
                //console.log(localStorage.userType)
            },
        },
        methods: {},
        mounted () {

            localStorage.adminIP = '178.143.45.177' 

            //console.log('MOUNTED')
            this.PROC.getCurrentIP('App.vue', 'oCurrentIP')

            //Do budúcnoti len jedna AXIOS metóda pre načítanie tabuľky
            //this.PROC.getAccessTypes(           'App.vue', 'oAccessTypes')
            //this.PROC.getChargers(              'App.vue', 'oChargers')
            //this.PROC.getChargers(              'App.vue', 'oAllChargers')
            
            //this.PROC.getOwners(                'App.vue', 'oOwners')

            ////this.PROC.getPromoVideos(           'App.vue', 'oPromoVideos')

            
            //this.PROC.getOwnerShipOnly(         'App.vue', 'oOwnerShip')
            //this.PROC.usersDashboard(           'App.vue', 'oUsersDashboard')

            let isLocalHost = window.location.href.toLowerCase().indexOf('http://localhost:')>(-1)

            if(isLocalHost)
                localStorage.workSpace = 'developer'

            let URL = ''
            if(!isLocalHost && window.location.href.trim().toLowerCase().indexOf('https:')!==0)  {
                let aaURL = window.location.href.split('/')
                let comma = ''

                for(let i=0;i<aaURL.length;++i)  {
                    if(i===0) URL += 'https:';   else  URL += comma + aaURL[i];
                    comma = '/'    
                }
                window.location.href = URL
            }            

            this.PROC.flagForEachCharger('App.vue')

            isLocalHost = window.location.href.toLowerCase().indexOf('http://localhost:')>(-1)
            let projectName = ''
            if(isLocalHost) 
                projectName  = setApp.localhost        //'local'
            else
                if(window.location.href.indexOf('dev-dash')>(-1))
                    projectName  = setApp.tester       //'tester'
                else
                    projectName  = setApp.projectName      //      'econec'           

            localStorage.projectName = projectName

            switch(projectName) {
                case 'local':  {
                    localStorage.URLRestApi =   setApp.localApi     //'https://local-api.econec.tech/'
                    break
                }
                case 'tester':  {
                    localStorage.URLRestApi =   setApp.testApi      //'https://test-api.econec.tech/'
                    break
                }
                case 'econec':  {
                    //localStorage.URLRestApi =   setApp.restApi      //'https://rest-api.econec.tech/'
                    localStorage.URLRestApi =   setApp.currentApi     //'https://api-2023-08.econec.tech/'
                    break
                }                
            }

            

            /*
            console.log(localStorage.URLRestApi)
            if(localStorage.projectName==='local')
                localStorage.URLRestApi =   'https://test-api.econec.tech/'
            else
                localStorage.URLRestApi =   'https://rest-api.econec.tech/'
            console.log(setApp.data.weather)
            */
            
           
            localStorage.weatherIcons   =   setApp.data.weather     //  'https://data.econec.tech/weather/'
            localStorage.URLFlags       =   setApp.data.flags       //  'https://data.econec.tech/flags-svg/'
            localStorage.URLOther       =   setApp.data.others      //  'https://data.econec.tech/others/'
            localStorage.logos          =   setApp.data.logos       //  'https://data.econec.tech/logos/'
            localStorage.URLUpload      =   setApp.data.upload      //  'http://data.econec.tech/'
            localStorage.pathIcons      =   setApp.data.icons       //  'https://data.econec.tech/icons/'
            localStorage.pathSliders    =   setApp.data.sliders     //  'https://data.econec.tech/sliders/'

            if(isLocalHost)
                localStorage.uploadAxiosForCMS  =   setApp.upload.cms           //  'http://cms-upload.econec.tech/'
            else    
                localStorage.uploadAxiosForCMS  =   setApp.upload.cms_ssl       //  'https://cms-images.econec.tech/'


            if(isLocalHost)
                localStorage.uploadAxiosVideo  =   setApp.upload.videos         //'http://videos-upload.econec.tech/'
            else    
                localStorage.uploadAxiosVideo  =   setApp.upload.videos_ssl     //'https://videos.econec.tech/'                

            localStorage.cmsImages  =   setApp.images           //  'http://cms-images.econec.tech/'
            
            localStorage.videosPath =   setApp.videos           //  'https://videos.econec.tech/'
            localStorage.videoExt   =   setApp.videos_suffix    //  'mp4,mp8'
            
            
            if(isLocalHost)
                localStorage.URLUploadAxios =   setApp.upload.sliders       //  'http://upload.econec.tech/'
            else
                localStorage.URLUploadAxios =   setApp.upload.sliders_ssl   //  'https://data.econec.tech/'

            if(isLocalHost) 
                localStorage.URLUpoadEMachines  =   setApp.upload.emachines         //  'http://e-machines.econec.tech/'
            else
                localStorage.URLUpoadEMachines  =   setApp.upload.emachines_ssl     //  'https://e-machines-ssl.econec.tech/'

            if(isLocalHost) 
                localStorage.URLUploadConnectors    =   setApp.upload.connectors        //  'http://connectors.econec.tech/'
            else
                localStorage.URLUploadConnectors    =   setApp.upload.connectors_ssl    //  'https://connectors-ssl.econec.tech/'

            //localStorage.pathIcons          =   'https://data.econec.tech/icons/'

            localStorage.machinesPath       =   setApp.emachines    //  'https://data.econec.tech/e-machines/'
            localStorage.connectorsPath     =   setApp.connectors   //   'https://data.econec.tech/connectors/'

            localStorage.editLine_1         =   ''
            localStorage.editLine_2         =   ''

            localStorage.editContent_1      =   ''
            localStorage.editContent_2      =   ''

            localStorage.loginForAxios      =   ''
            localStorage.passwordForAxios   =   ''


            localStorage.deleteAfterDays    =   setApp.delete_after_days    //   3

            localStorage.topIsFixed         =   setApp.head_is_fixed        //  1

            localStorage.limitMsgView       =   setApp.limit_message_view   //  7000
        },
        unmounted() {},
    };
</script>

<style lang="scss" scoped>

</style>
